import { createStore } from 'redux';

// Breadcrumb Reducer
let crumbReducer = (state = [], action) => {
	switch(action.type) {
		case 'ADD_CRUMB':
			return [...state, action.crumb].sort(standalonePathsort('/'));
		case 'REMOVE_CRUMB':
			return state.filter(crumb => crumb.id !== action.crumb.id);
		case 'UPDATE_CRUMB':
			return state.map(crumb => crumb.id === action.crumb.id ? action.crumb : crumb);
		default:
			return state;
	}
}

// Export Utils
export let crumbStore = createStore(crumbReducer);
export const dispatch = crumbStore.dispatch;

function sorter(a, b) {
  var l = Math.max(a.length, b.length)
  for (var i = 0; i < l; i += 1) {
    if (!(i in a)) return -1
    if (!(i in b)) return +1
    if (a[i].toUpperCase() > b[i].toUpperCase()) return +1
    if (a[i].toUpperCase() < b[i].toUpperCase()) return -1
    if (a.length < b.length) return -1
    if (a.length > b.length) return +1
  }
}

function standalonePathsort(sep) {
  sep = sep || '/'
  return function pathsort(a, b) {
    return sorter(String(a).split(sep), String(b).split(sep))
  }
}
