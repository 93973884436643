import React from 'react';
import styled from 'styled-components';

export const SelectType = {
	Item: 'item',
	Group: 'group'
}

export const OuSelectInput = styled.select`
	box-sizing: border-box;
	appearance: none;
`;

export const OuSelect = ({ config = [], hideEmpty = false, notEmpty = false, ...props }) => {
	return (
		<OuSelectInput {...props}>
			{!hideEmpty && (
				<option value="" hidden={notEmpty} disabled={notEmpty}>
					{'<Keine Auswahl>'}
				</option>
			)}
			{// eslint-disable-next-line
			Array.isArray(config) && config.map((item, i) => {
				if(typeof item === 'object' && item.hasOwnProperty('type') && item.hasOwnProperty('label')) {
					switch(item.type) {
						case SelectType.Group:
							const { label: groupLabel, groupItems = [], ...groupProps } = item;
							//
							if(Array.isArray(groupItems) && groupItems.length > 0) {
								return (
									<optgroup label={groupLabel} {...groupProps}>
										{groupItems.map((groupItem, g) => {
											const { label, value = undefined, ...itemProps } = groupItem;
											//
											return (
												<option
													key={i + ':' + g} {...itemProps}
													value={
														groupItem.hasOwnProperty('value')
														? value
														: encodeURIComponent(label)
													}>
													{label}
												</option>
											);
										})}
									</optgroup>
								);
							}
							break;
						case SelectType.Item:
							const { label, value = undefined, ...itemProps } = item;
							//
							return (
								<option
									key={i} {...itemProps}
									value={
										item.hasOwnProperty('value')
										? value
										: encodeURIComponent(label)
									}>
									{label}
								</option>
							);
						default:
							return null;
					}
				} else {
					return null;
				}
			})}
		</OuSelectInput>
	);
};
