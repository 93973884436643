module.exports = function blacklist (src) {
	var copy = {}
	var filter = arguments[1]

	if (typeof filter === 'string') {
		filter = {}
		for (var i = 1; i < arguments.length; i++) {
		  filter[arguments[i]] = true
		}
	}

	for (var key in src) {
		// blacklist?
		if (filter[key]) continue

		copy[key] = src[key]
	}

	return copy
}
