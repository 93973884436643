import React from 'react';
import styled from 'styled-components';
import { Spring } from 'react-spring';

const NotificationWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
	height: auto;
	min-height: 55px;
    z-index: 999999;

	font-family: "Open Sans", sans-serif;
    padding: 0.5rem 1rem;
    background-color: #e61453;

	body.smileys & {
		background-color: #d1060c;
	}

	@media (max-width: 449px) {
		flex-direction: column;
		align-items: stretch;
		padding: 1rem;
	}
`;

const NotificationText = styled.p`
    flex: 1;
    font-weight: bold;
    font-size: 1rem;
    color: #ffffff;
    margin: 0;
    margin-right: 1rem;

	@media (max-width: 449px) {
		margin: 0;
		margin-bottom: 0.5rem;
	}
`;

const NotificationActions = styled.div`
    display: flex;
    flex-direction: row;
	justify-content: space-between;
`;

const NotificationRefresh = styled.button.attrs({
	type: 'button'
})`

	text-transform: uppercase;
	color: #a86415;
	background-color: #f0dcc0;
	padding: 0.5rem 1rem;
	border: 0;
	margin-right: 1rem;

	transition: background-color 0.3s;

	body.smileys & {
		color: #6c4233;
		background-color: #e2d5c3;
	}

	&:hover {
		background-color: #ffffff;

		body.smileys & {
			background-color: #ffffff;
		}
	}
`;

const NotificationClose = styled.button.attrs({
	type: 'button'
})`
	display: flex;
	justify-content: center;
	align-items: center;
	background: none;
	padding: 0;
	border: 0;

	& > svg {
		height: 2rem;
		width: 2rem;
	}

	& > svg > path {
		fill: #f0dcc0;
		transition: fill 0.3s;

		body.smileys & {
			fill: #e2d5c3;
		}
	}

	&:hover > svg > path {
		fill: #ffffff;

		body.smileys & {
			fill: #ffffff;
		}
	}
`;

export class NewContentNotifcation extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			hasNewContent: false,
			rejected: false
		}

		this.handleCancel = this.handleCancel.bind(this);
	}

	componentDidMount() {
		if(document) {
			document.addEventListener('sw-new-content', this.handleNewContent);
		}
	}

	componentWillUnmount() {
		if(document) {
			document.removeEventListener('sw-new-content', this.handleNewContent);
		}
	}

	handleNewContent = () => {
		this.setState({ hasNewContent: true });
	}

	handleReload = (event) => {
		event.preventDefault();
		if(window && window.location) {
			window.location.reload();
		}
	}

	handleCancel = (event) => {
		event.preventDefault();
		this.setState({ rejected: true });
	}

	render() {
		const { hasNewContent, rejected } = this.state;

		return (
			<Spring to={{ opacity: hasNewContent && !rejected ? 1 : 0 }}>
				{fadeOut => fadeOut.opacity !== 0 ? (
					<NotificationWrapper style={fadeOut}>
						<NotificationText>
							Eine neue Version ist verfügbar.
						</NotificationText>
						<NotificationActions>
							<NotificationRefresh onClick={this.handleReload}>
								Neu laden
							</NotificationRefresh>
							<NotificationClose onClick={this.handleCancel} title="Später laden">
								<svg aria-hidden="true" viewBox="0 0 320 512" height="2rem" width="2rem">
									<path
										d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
									/>
								</svg>
							</NotificationClose>
						</NotificationActions>
					</NotificationWrapper>
				) : null}
			</Spring>
		);
	}
}
