// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import { authRoute } from '../../../../actions/auth';

class OuBreadcrumb2 extends Component {
	render() {
		const _this = this;

		return (
			<ol className={'ouBreadcrumb breadcrumb'}>
				{_this.props.breadcrumb.map((crumb, index) => {
					return (
						<span className={'ouBreadcrumbItem breadcrumb-item'} key={ index }>
							<button className={'ouBreadcrumbLink breadcrumb-link'} onClick={() => {
								_this.props.authRoute(crumb.url);
							}}>{crumb.text}</button>
						</span>
					);
				})}
			</ol>
		);
    }
}

OuBreadcrumb2.propTypes = {
	breadcrumb: PropTypes.array.isRequired
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch) => {
	return {
		authRoute: (path) => {
			dispatch(authRoute(path));
		}
	}
}

const OuBreadcrumbMapped = connect(mapStateToProps, mapDispatchToProps)(OuBreadcrumb2);
export default OuBreadcrumbMapped;
