// React
import React, { useEffect, Suspense } from 'react';
// React Router
// import { withRouter } from 'react-router'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
// Components
import { OuLogin } from 'components/pages';
// Styles
import 'bootstrap/dist/css/bootstrap.css';
import 'assets/css/font-awesome.min.css';
import 'assets/css/animate.css';
import 'assets/css/orderu_custom.css';
import 'assets/css/orderu.css';
import { NewContentNotifcation } from './components';

// Components (Async)
const OuCore = React.lazy(() => import(
	/* webpackChunkName: 'ou_core' */
	'components/OuCore'
));
const OuForgot = React.lazy(() => import(
	/* webpackChunkName: 'ou_forgot' */
	'components/pages/auth/OuForgot'
));
const OuReset = React.lazy(() => import(
	/* webpackChunkName: 'ou_reset' */
	'components/pages/auth/OuReset'
));

const OuRegistry = ({ history, location, match }) => {
	return (
		<Suspense fallback={<p>Lädt...</p>}>
			<Switch history={history} location={location} match={match}>
				<Route exact path="/login" component={OuLogin} />
				<Route exact path="/forgot" component={OuForgot} />
				<Redirect exact from="/reset" to="/forgot" />
				<Route exact path="/reset/:token" component={OuReset} />
				<Route path="/" component={OuCore} />
			</Switch>
		</Suspense>
	);
}

const OuConnectedRegistry = withRouter(OuRegistry);

const OuMain = () =>  {
	// Add Target CSS Class
	useEffect(() => {
		if(document) {
			document.body.classList.add(process.env.REACT_APP_TARGET || '');

			// Remove Class on Cleanup
			return () => {
				document.body.classList.remove(process.env.REACT_APP_TARGET || '');
			}
		}
	});

	return (
		<div className={'ouApp'}>
			<NewContentNotifcation />
			<OuConnectedRegistry />
		</div>
	);
}

const OuApp = () => (
	<OuMain />
);

export default OuApp;
