const initialState = {
	cacheKey: null,
	cache: {},
	items: [],
	total: undefined,
	page: 0,
	pages: -1,
	max: 10,
	filtered: [],
	sorted: [],
	loading: false,
	loadQueued: false,
};

export const generatePaginatedReducer = (type, props = {}) => {
	const { inital = {} } = props;
	const _initalState = { ...initialState, ...inital };

	return (state = _initalState, action) => {
		switch(action.type) {
			case `${type}_FETCH`:
				return {
					...state,
					loading: true,
					loadQueued: false,
				};
			case `${type}_UPDATE`:
				const { update } = action,
					hasFilter = !!update.filtered,
					newFilter = hasFilter && !Object.is(JSON.stringify(state.filtered), JSON.stringify(update.filtered));

					console.log('Updateing ', hasFilter, newFilter, update);

				return {
					...state,
					...action.update,
					...(newFilter ? { page: 0, loadQueued: true } : {}),
				};
			case `${type}_FETCHED`:
				return {
					...state,
					...action.update,
					loading: false
				}
			case `${type}_EMPTY`:
				return {
					...state,
					items: [],
					total: undefined,
					page: 0,
					pages: 0,
					loading: false
				};
			case `${type}_RESET`:
				return {
					..._initalState,
				};
			default:
				return state;
		}
	}
}
