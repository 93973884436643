// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
//
import FallbackImage from '../../../assets/img/logo_u.png';

class OuImage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			error: false
		};
	}

	render() {
		const _this = this;

		if(_this.state.error || _this.props.error) {
			return (
				<img className={'ouImage' + (_this.props.className ?  (' ' + _this.props.className) : '')}
					 src={_this.props.fallbackSource || FallbackImage}
					 style={_this.props.style || null}
					 title={_this.props.title || null}
					 alt={_this.props.alt || null}
				/>
			)
		} else {
			return (
				<img className={'ouImage' + (_this.props.className ?  (' ' + _this.props.className) : '')}
					 src={_this.props.source}
					 style={_this.props.style || null}
					 title={_this.props.title || null}
					 alt={_this.props.alt || null}
					 onError={() => {
						 _this.setState({
						 	error: true
						 });
					 }}
				/>
			)
		}
	}
}

OuImage.propTypes = {
	source: PropTypes.string.isRequired,
	fallbackSource: PropTypes.string,
	className: PropTypes.string,
	style: PropTypes.object,
	title: PropTypes.string,
	alt: PropTypes.string
}

export default OuImage;
