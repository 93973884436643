// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OuAlertContainer extends Component {
	render() {
		const _this = this;

		return (
			<div className={'ouAlert ' + _this.props.position} style={{padding: _this.props.wrapper}}>
				{_this.props.children ? (_this.props.children) : null}
			</div>
		)
	}
}

OuAlertContainer.propTypes = {
	position: PropTypes.oneOf([
		't-l', 't-c', 't-r', 'c-l', 'c-c', 'c-r', 'b-l', 'b-c', 'b-r'
	]),
	wrapper: PropTypes.string,
	children: PropTypes.node
}

OuAlertContainer.defaultProps = {
	position: 't-r',
	wrapper: '1rem'
}

export default OuAlertContainer;
