// React
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { authCheck, authLogin } from 'actions/auth';
// HTTP
import axios from 'axios';
import { BASE_URL } from 'config/api';
// Components
import Logo from 'assets/img/logo.svg';
import LogoSmileys from 'assets/img/___/smileys/logo.png';
import { OuAlert } from 'components/parts/universal';

class OuLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
			running: false,
			last: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    UNSAFE_componentWillMount() {
		this.props.authCheck();
    }

    handleChange(event) {
        const value =  event.target.value;
        const name =  event.target.name;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        event.preventDefault();
		const _this = this;
		const _user = this.state.username;
		const _pass = this.state.password;

		/* Basic Length Validation */
		if(!_user || _user.length < 5 || !_pass || _pass.length < 8) {
			_this.alert.show({
				type: 'info',
				message: 'Ihre Angaben sind leider nicht gültig.',
				timeout: 2500
			});

			return;
		}

		/* Spam Prevention */
		if(_this.state.last !== null) {
			if(!(_this.state.last < (Date.now() - 3000))) {
				return;
			}
		}
		_this.setState({
			last: Date.now(),
			running: true
		});

		axios.post(`${BASE_URL}/user/auth/login`, {
			user_name: _user,
			user_password: _pass
		})
		.then(response => response.data)
		.then(json => {
			if(json && json.status === true) {
				_this.props.authLogin(json.token);
			} else {
				_this.setState({
					running: false
				});

				_this.alert.show({
					type: 'error',
					message: 'Der Benutzername und das Passwort stimmen nicht überein. Bitte überprüfen sie Ihre Angaben.',
					timeout: 2500
				});
			}
		})
		.catch((error) => {
			console.error(error);
			_this.alert.show({
				type: 'error',
				message: 'Der Benutzername und das Passwort stimmen nicht überein. Bitte überprüfen sie Ihre Angaben.',
				timeout: 2500
			});
			_this.setState({
				running: false
			});
		});
    }

    render() {
		const _this = this;
		const _auth = this.props.authState;
		const _pass = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9A-Za-z!#$%]{8,32}$/;

		if(_auth.authenticated) {
			return (
				<Redirect to="/dashboard" />
			)
		} else {
			return (
				<div className="ouPage pageAuth pageLogin">
					<OuAlert ref={(alert) => { _this.alert = alert; }} position={'t-c'} />
					<div className="ouAuth--wrapper">
						<div className="ouAuth--grid">
							<div className="ouAuth--card">
								<form className="ouAuth--form" onSubmit={_this.handleSubmit} autoComplete="off" >
									<img
										className="ouAuth--logo"
										src={process.env.REACT_APP_TARGET === 'smileys' ? LogoSmileys : Logo}
										alt={process.env.REACT_APP_TARGET === 'smileys' ? 'Smiley´s Pizza Profis' : 'ORDERU - Das Shopsystem'}
									/>
									<h3 className="ouAuth--headline">Einloggen</h3>
									<div className="ouAuth--form--field">
										<label htmlFor="username">
											<span className="ouAuth--icon"><i className="fa fa-user" /></span>
											<span className="sr-only">Benutzername / E-Mail Adresse</span>
										</label>
										<input className="ouAuth--form--input" type="text" name="username" placeholder="Benutzername / E-Mail Adresse" value={_this.state.username} onChange={_this.handleChange} disabled={_this.state.running} />
									</div>
									<div className="ouAuth--form--field">
										<label htmlFor="password">
											<span className="ouAuth--icon"><i className="fa fa-lock" /></span>
											<span className="sr-only">Password</span>
										</label>
										<div className={'ouAuth--form--pass' + (_this.state.running ? ' disabled' : '')}>
											<input className="ouAuth--form--input" ref={(input) => { _this.passInput = input; }} type="password" name="password" placeholder="Passwort" value={_this.state.password} onChange={_this.handleChange} disabled={_this.state.running} />
											<span className="ouAuth--form--pass--show" onMouseDown={(event) => {
												if(_this.state.running) return;
												_this.passInput.type = 'text';
											}} onMouseUp={(event) => {
												_this.passInput.type = 'password';
											}}><i className="fa fa-eye" /></span>
										</div>
									</div>
									<div className="ouAuth--form--field">
										<button className={'ouAuth--submit'} type="submit" disabled={
											_this.state.running || _this.state.username.length < 4 || !_pass.test(_this.state.password)
										}>
											{_this.state.running ? (
												<div>
													<i className="fa fa-refresh fa-spin fa-fw" />
													{' '}<span>Einloggen</span>
												</div>
											) : (
												<span>Einloggen</span>
											)}
										</button>
									</div>
								</form>
							</div>

							<p className="ouAuth--forgot">
								<button onClick={() => {
									_this.props.pushRouter('/forgot');
								}}>Passwort vergessen?</button>
							</p>
						</div>
					</div>
	            </div>
			)
		}
    }
}

const mapStateToProps = (state) => {
	return {
		authState: state.auth
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		authCheck: () => {
			dispatch(authCheck());
		},
		authLogin: (token) => {
			dispatch(authLogin(token));
		},
		pushRouter: (path) => {
			dispatch(push(path));
		}
	}
}

const OuLoginMapped = connect(mapStateToProps, mapDispatchToProps)(OuLogin);
export default OuLoginMapped;
