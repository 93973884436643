// React / Router
import React, { Component } from 'react';
// Redux
import { connect } from 'react-redux';
import { authCheck, authRoute } from 'actions/auth';
// HTTP
import axios from 'axios';
import { BASE_URL } from 'config/api';
// Components
import { OuAlert } from 'components/parts/universal';
import { OuPanel, OuPanelHeading, OuPanelTitle, OuPanelBody } from 'components/parts/panel';

class OuAccount extends Component {
	constructor(props) {
		super(props);

		this.state = {
			passwordChanging: false,
			passwordChanged: false,
			currentPassword: '',
			newPassword: '',
			newConfirm: '',
			passLast: null
		}
	}

	UNSAFE_componentWillMount() {
		this.props.authCheck();
    }


    handleChange = (event) => {
        const value =  event.target.value;
        const name =  event.target.name;
        this.setState({ [name]: value });
    }

    render() {
		const _this = this;
		const _pass = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9A-Za-z!#$%]{8,32}$/;
		const _leng = /^[0-9A-Za-z!#$%]{8,32}$/;
		const _numb = /^(?=.*[0-9])/;
		const _form = /^(?=.*[a-z])(?=.*[A-Z])/;

        return (
            <div className="ouPage pageDashboard">
				<OuAlert ref={(alert) => { _this.alert = alert; }} position={'t-c'} />
				<div className="container-fluid">
					<div className="row" style={{marginBottom: '1.25rem'}}>
						<div className="col-12 col-lg-6 col-xl-4">
							<OuPanel>
								<OuPanelHeading>
									<OuPanelTitle>
										<h4>Passwort ändern</h4>
									</OuPanelTitle>
								</OuPanelHeading>
								<OuPanelBody>
									<div className="ouAuth--form" style={{margin: 0}}>
										<ul className="ouAuth--pass-verify">
											<li className="ouAuth--pass-verify--entry">
												<div className={'ouAuth--pass-verify--item' + (_form.test(_this.state.currentPassword) ? ' valid' : '')}>
													<span className="ouAuth--pass--headline">A+a</span>
													<span className="ouAuth--pass--info">Form</span>
												</div>
											</li>
											<li className="ouAuth--pass-verify--entry">
												<div className={'ouAuth--pass-verify--item' + (_numb.test(_this.state.currentPassword) ? ' valid' : '')}>
													<span className="ouAuth--pass--headline">1-9</span>
													<span className="ouAuth--pass--info">Zahl</span>
												</div>
											</li>
											<li className="ouAuth--pass-verify--entry">
												<div className={'ouAuth--pass-verify--item' + (_leng.test(_this.state.currentPassword) ? ' valid' : '')}>
													<span className="ouAuth--pass--headline">8+</span>
													<span className="ouAuth--pass--info">Länge</span>
												</div>
											</li>
										</ul>

										<div className="ouAuth--form--field">
											<label htmlFor="username">
												<span className="ouAuth--icon"><i className="fa fa-question" /></span>
												<span className="sr-only">Momentanes Passwort</span>
											</label>

											<div className={'ouAuth--form--pass' + (_this.state.passwordChanging || _this.state.passwordChanged ? ' disabled' : '')}>
												<input className="ouAuth--form--input" ref={(input) => { _this.passCurrent = input; }} type="password" name="currentPassword" placeholder="Momentanes Passwort" value={_this.state.currentPassword} onChange={_this.handleChange} disabled={_this.state.passwordChanging || _this.state.passwordChanged} />
												<span className="ouAuth--form--pass--show" onMouseDown={(event) => {
													if(_this.state.passwordChanging || _this.state.passwordChanged) return;
													_this.passCurrent.type = 'text';
												}} onMouseUp={(event) => {
													_this.passCurrent.type = 'password';
												}}><i className="fa fa-eye" /></span>
											</div>
										</div>

										<ul className="ouAuth--pass-verify">
											<li className="ouAuth--pass-verify--entry">
												<div className={'ouAuth--pass-verify--item' + (_form.test(_this.state.newPassword) ? ' valid' : '')}>
													<span className="ouAuth--pass--headline">A+a</span>
													<span className="ouAuth--pass--info">Form</span>
												</div>
											</li>
											<li className="ouAuth--pass-verify--entry">
												<div className={'ouAuth--pass-verify--item' + (_numb.test(_this.state.newPassword) ? ' valid' : '')}>
													<span className="ouAuth--pass--headline">1-9</span>
													<span className="ouAuth--pass--info">Zahl</span>
												</div>
											</li>
											<li className="ouAuth--pass-verify--entry">
												<div className={'ouAuth--pass-verify--item' + (_leng.test(_this.state.newPassword) ? ' valid' : '')}>
													<span className="ouAuth--pass--headline">8+</span>
													<span className="ouAuth--pass--info">Länge</span>
												</div>
											</li>
											<li className="ouAuth--pass-verify--entry">
												<div className={'ouAuth--pass-verify--item' + (_pass.test(_this.state.newPassword) && (_this.state.newPassword === _this.state.newConfirm) ? ' valid' : '')}>
													<span className="ouAuth--pass--headline">==</span>
													<span className="ouAuth--pass--info">Gleich</span>
												</div>
											</li>
										</ul>

										<div className="ouAuth--form--field">
											<label htmlFor="username">
												<span className="ouAuth--icon"><i className="fa fa-lock" /></span>
												<span className="sr-only">Neues Passwort</span>
											</label>

											<div className={'ouAuth--form--pass' + (_this.state.passwordChanging || _this.state.passwordChanged ? ' disabled' : '')}>
												<input className="ouAuth--form--input" ref={(input) => { _this.passInput = input; }} type="password" name="newPassword" placeholder="Neues Passwort" value={_this.state.newPassword} onChange={_this.handleChange} disabled={_this.state.passwordChanging || _this.state.passwordChanged} />
												<span className="ouAuth--form--pass--show" onMouseDown={(event) => {
													if(_this.state.passwordChanging || _this.state.passwordChanged) return;
													_this.passInput.type = 'text';
													_this.passConfirm.type = 'text';
												}} onMouseUp={(event) => {
													_this.passInput.type = 'password';
													_this.passConfirm.type = 'password';
												}}><i className="fa fa-eye" /></span>
											</div>
										</div>

										<div className="ouAuth--form--field">
											<label htmlFor="username">
												<span className="ouAuth--icon"><i className="fa fa-check" /></span>
												<span className="sr-only">Neues Passwort bestätigen</span>
											</label>

											<input className="ouAuth--form--input" type="password" name="newConfirm" placeholder="Neues Passwort bestätigen" value={_this.state.newConfirm} onChange={_this.handleChange} disabled={_this.state.passwordChanging || _this.state.passwordChanged} ref={(input) => { _this.passConfirm = input; }} />
										</div>

										<div className="ouAuth--form--field">
											{_this.state.passwordChanging ? (
												<button className="ouAuth--submit" disabled>
													<i className="fa fa-refresh fa-spin fa-fw" />
													{' '}<span>Ändern</span>
												</button>
											) : (
												<button className={'ouAuth--submit' + (_this.state.passwordChanged ? ' success' : '')} disabled={
													_this.state.passwordChanged || !_pass.test(_this.state.currentPassword) || !_pass.test(_this.state.newPassword) || _this.state.newPassword !== _this.state.newConfirm
												} onClick={_this.changePassword}>
													{_this.state.passwordChanged ? (
														<div>
															<i className="fa fa-check fa-fw" />
															{' '}<span>Geändert!</span>
														</div>
													) : (
														<span>Ändern</span>
													)}
												</button>
											)}
										</div>
									</div>
								</OuPanelBody>
							</OuPanel>
						</div>
	                </div>
				</div>
            </div>
        );
    }

	// API Calls
	changePassword = () => {
		const _this = this;

		/* Spam Prevention */
		if(_this.state.passLast !== null) {
			if(!(_this.state.passLast < (Date.now() - 2000))) {
				return;
			}
		}

		_this.setState({
			passLast: Date.now(),
			passwordChanging: true
		});

		axios.put(`${BASE_URL}/user/update/password`, {}, {
			params: {
				user_password: _this.state.newPassword,
				user_password_confirm: _this.state.newConfirm,
				user_password_current: _this.state.currentPassword
			}
		})
		.then(response => response.data)
		.then(json => {
			if(json.status && json.status === true) {
				_this.setState({
					passwordChanging: false,
					passwordChanged: true
				});
			} else {
				_this.alert.show({
					type: 'error',
					message: 'Passwort konnte nicht geändert werden.',
					timeout: 2500
				});
				_this.setState({
					passwordChanging: false,
					passwordChanged: false,
					currentPassword: '',
					newPassword: '',
					newConfirm: ''
				});
			}
		})
		.catch((error) => {
			console.error(error);
			_this.alert.show({
				type: 'error',
				message: 'Ein unbekannter Fehler ist aufgetreten.',
				timeout: 2500
			});
			_this.setState({
				passwordChanging: false,
				passwordChanged: false,
				currentPassword: '',
				newPassword: '',
				newConfirm: ''
			});
		});
	}
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch) => {
	return {
		authCheck: () => {
			dispatch(authCheck());
		},
		authRoute: (path) => {
			dispatch(authRoute(path));
		}
	}
}

const OuAccountMapped = connect(mapStateToProps, mapDispatchToProps)(OuAccount);
export default OuAccountMapped;
