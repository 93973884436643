// React
import React, { Suspense } from 'react';
import { withRouter } from 'react-router';
import { Switch, Redirect } from 'react-router-dom';
// Custom Route
import { OuRoute, OuRedirect } from 'components/parts/universal';

// Components (Async)
const OuStoreDetail = React.lazy(() => import(
	/* webpackChunkName: 'ou_store_detail' */
	'./detail/OuStoreDetail'
));
const OuStoreOpening = React.lazy(() => import(
	/* webpackChunkName: 'ou_store_opening' */
	'./opening/OuStoreOpening'
));
const OuStoreDeliveryModeSelect = React.lazy(() => import(
	/* webpackChunkName: 'ou_store_delivery' */
	`./delivery/OuStoreDeliveryModeSelect`
));

const OuStoreSelect = ({ history, location, match }) => (
	<Suspense fallback={<p>Lädt...</p>}>
		<Switch history={history} location={location} match={match} >
			<OuRedirect exact from='/store/:hash' to='/store/:hash/detail' />
			<OuRoute title="Details" path="/store/:hash/detail" component={OuStoreDetail} />
			<OuRoute title="Öffnungszeiten" path="/store/:hash/opening" component={OuStoreOpening} />
			<OuRoute title="Liefergebiete" path="/store/:hash/delivery/:mode" component={OuStoreDeliveryModeSelect} />
			<OuRedirect from='/store/:hash/delivery' to='/store/:hash/delivery/zipcode' />
			{/* No Match */}
			<Redirect from='/store/:hash' to='/store/overview/10/1' />
		</Switch>
	</Suspense>
);

export default withRouter(OuStoreSelect);
