// React
import React, { Component } from 'react';
// Redux
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
// HTTP
import axios from 'axios';
import { BASE_URL } from 'config/api';
// Components
import Logo from 'assets/img/logo.svg';
import LogoSmileys from 'assets/img/___/smileys/logo.png';
import { OuAlert } from 'components/parts/universal';

class OuReset extends Component {
	constructor(props) {
		super(props);

		this.state = {
			token: this.props.match.params.token,
			password: '',
			confirm: '',
			running: false,
			success: false,
			last: null
		}

		this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}

	UNSAFE_componentWillMount() {
		const _params = this.props.match.params;
		if(!_params.token) {
			this.props.pushRouter('/forgot');
		}
	}


	handleChange(event) {
        const value =  event.target.value;
        const name =  event.target.name;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        event.preventDefault();
		const _this = this;
		const _token = this.state.token;
		const _pass = this.state.password;

		/* Spam Prevention */
		if(_this.state.last !== null) {
			if(!(_this.state.last < (Date.now() - 2000))) {
				return;
			}
		}
		_this.setState({
			last: Date.now(),
			running: true
		});

		axios.post(`${BASE_URL}/user/auth/reset`, {
			token: _token,
			user_password: _pass
		})
		.then(response => response.data)
		.then(json => {
			if(json.status && json.status === true) {
				_this.setState({
					running: false,
					success: true
				});
			} else {
				_this.alert.show({
					type: 'error',
					message: 'Passwort konnte nicht zurückgesetzt werden.',
					timeout: 2500
				});
				_this.setState({
					password: '',
					confirm: '',
					running: false,
					success: false
				});
			}
		})
		.catch((error) => {
			console.error(error);
			_this.alert.show({
				type: 'error',
				message: 'Ein unbekannter Fehler ist aufgetreten.',
				timeout: 2500
			});
			_this.setState({
				password: '',
				confirm: '',
				running: false,
				success: false
			});
		});
    }

    render() {
		const _this = this;
		const _pass = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9A-Za-z!#$%]{8,32}$/;
		const _leng = /^[0-9A-Za-z!#$%]{8,32}$/;
		const _numb = /^(?=.*[0-9])/;
		const _form = /^(?=.*[a-z])(?=.*[A-Z])/;

		let _progress = 0;
		let _color = '#cccccc';
		if(_leng.test(_this.state.password)) _progress += 25;
		if(_form.test(_this.state.password)) _progress += 25;
		if(_numb.test(_this.state.password)) _progress += 25;
		if(_pass.test(_this.state.password) && (_this.state.password === _this.state.confirm)) _progress += 25;
		if(_progress === 100) {
			_color = '#5cb85c';
		} else if(_progress > 50 && _progress <= 75) {
			_color = '#5bc0de';
		} else if(_progress > 25 && _progress <= 50) {
			_color = '#f0ad4e';
		} else if(_progress > 0 && _progress <= 25) {
			_color = '#d9534f';
		} else {
			_color = '#cccccc';
		}

        return (
            <div className="ouPage pageAuth pageForgot">
				<OuAlert ref={(alert) => { _this.alert = alert; }} position={'t-c'} />
				<div className="ouAuth--wrapper">
					<div className="ouAuth--grid">
						<div className="ouAuth--card">
							<form className="ouAuth--form" onSubmit={_this.handleSubmit} autoComplete="off" >
								<img
									className="ouAuth--logo"
									src={process.env.REACT_APP_TARGET === 'smileys' ? LogoSmileys : Logo}
									alt={process.env.REACT_APP_TARGET === 'smileys' ? 'Smiley´s Pizza Profis' : 'ORDERU - Das Shopsystem'}
								/>
								<h3 className="ouAuth--headline">Passwort zurücksetzen</h3>
								<ul className="ouAuth--pass-verify">
									<li className="ouAuth--pass-verify--entry">
										<div className={'ouAuth--pass-verify--item' + (_form.test(_this.state.password) ? ' valid' : '')}>
											<span className="ouAuth--pass--headline">A+a</span>
											<span className="ouAuth--pass--info">Form</span>
										</div>
									</li>
									<li className="ouAuth--pass-verify--entry">
										<div className={'ouAuth--pass-verify--item' + (_numb.test(_this.state.password) ? ' valid' : '')}>
											<span className="ouAuth--pass--headline">1-9</span>
											<span className="ouAuth--pass--info">Zahl</span>
										</div>
									</li>
									<li className="ouAuth--pass-verify--entry">
										<div className={'ouAuth--pass-verify--item' + (_leng.test(_this.state.password) ? ' valid' : '')}>
											<span className="ouAuth--pass--headline">8+</span>
											<span className="ouAuth--pass--info">Länge</span>
										</div>
									</li>
									<li className="ouAuth--pass-verify--entry">
										<div className={'ouAuth--pass-verify--item' + (_pass.test(_this.state.password) && (_this.state.password === _this.state.confirm) ? ' valid' : '')}>
											<span className="ouAuth--pass--headline">==</span>
											<span className="ouAuth--pass--info">Gleich</span>
										</div>
									</li>
								</ul>

								<div className="ouAuth--form--field">
									<label htmlFor="username">
										<span className="ouAuth--icon"><i className="fa fa-lock" /></span>
										<span className="sr-only">Neues Passwort</span>
									</label>

									<div className={'ouAuth--form--pass' + (_this.state.running || _this.state.success ? ' disabled' : '')}>
										<input className="ouAuth--form--input" ref={(input) => { _this.passInput = input; }} type="password" name="password" placeholder="Neues Passwort" value={_this.state.password} onChange={_this.handleChange} disabled={_this.state.running || _this.state.success} />
										<span className="ouAuth--form--pass--show" onMouseDown={(event) => {
											if(_this.state.running || _this.state.success) return;
											_this.passInput.type = 'text';
											_this.passConfirm.type = 'text';
										}} onMouseUp={(event) => {
											_this.passInput.type = 'password';
											_this.passConfirm.type = 'password';
										}}><i className="fa fa-eye" /></span>
									</div>
								</div>
								<div className="ouAuth--form--field">
									<label htmlFor="username">
										<span className="ouAuth--icon"><i className="fa fa-check" /></span>
										<span className="sr-only">Bestätigung</span>
									</label>

									<input className="ouAuth--form--input" type="password" name="confirm" placeholder="Bestätigung" value={_this.state.confirm} onChange={_this.handleChange} disabled={_this.state.running || _this.state.success} ref={(input) => { _this.passConfirm = input; }} />
								</div>
								<div className="ouAuth--progress progress">
									<div className="ouAuth--progress--bar progress-bar" role="progressbar" style={{backgroundColor: _color, width: _progress + '%'}} />
								</div>
								<div className="ouAuth--form--field">
									{_this.state.running ? (
										<button className="ouAuth--submit" type="submit" disabled>
											<i className="fa fa-refresh fa-spin fa-fw" />
											{' '}<span>Zurücksetzen</span>
										</button>
									) : (
										<button className={'ouAuth--submit' + (_this.state.success ? ' success' : '')} type="submit" disabled={
											_this.state.success || !_pass.test(_this.state.password) || _this.state.password !== _this.state.confirm
										}>
											{_this.state.success ? (
												<div>
													<i className="fa fa-check fa-fw" />
													{' '}<span>Zurückgesetzt</span>
												</div>
											) : (
												<span>Zurücksetzen</span>
											)}
										</button>
									)}
								</div>
							</form>
						</div>
					</div>

					<p className="ouAuth--forgot">
						<button onClick={() => {
							_this.props.pushRouter('/login');
						}}>Zum Login</button>
					</p>
				</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch) => {
	return {
		pushRouter: (path) => {
			dispatch(push(path));
		}
	}
}

const OuResetMapped = connect(mapStateToProps, mapDispatchToProps)(OuReset);
export default OuResetMapped;
