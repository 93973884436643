import React from 'react';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
//
import { OuBreadcrumb } from 'components/parts/breadcrumb';

const OuRoute = ({ component: Component, includeSearch = false, render, ...props }) => (
  <Route
	  {...props}
	  render={routeProps => (
	    <OuBreadcrumb
			data={{
		    	title: props.title || (routeProps.location.state ? routeProps.location.state.title : 'Unknown'),
		    	pathname: routeProps.match.url,
		    	search: includeSearch ? routeProps.location.search : null,
		    }}
			hidden={props.hidden}
			disabled={props.disabled}
		>
	    	{Component ? <Component { ...routeProps } /> : render(routeProps)}
	    </OuBreadcrumb>
	  )}
	/>
);

export default withRouter(OuRoute);
