// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Utils
import { classnames as cx } from 'utils';
// Styles
import './OuStateButton.css';

class OuStateButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			current: (this.props.state || 'default')
		}

		// Internal
		this.hasState = this.hasState.bind(this);
		this.getState = this.getState.bind(this);
		// External
		this.setCurrent = this.setCurrent.bind(this);
		this.getCurrent = this.getCurrent.bind(this);
	}

	// Check if Config contains Button State
	hasState(state) {
		if(this.props.config) {
			if(this.props.config[state]) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	// Get Button State from Config
	getState(state) {
		if(this.props.config) {
			if(this.props.config[state]) {
				return this.props.config[state];
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	// Set Current Button State
	setCurrent(state) {
		if(this.getState(state)) {
			this.setState({
				current: state
			});
		} else {
			console.log('State "' + state + '" does not exists.');
		}
	}

	// Get Current Button State
	getCurrent(state) {
		return this.state.current;
	}

	shouldComponentUpdate(nextProps, nextState) {
		if(this.state.current !== nextState.current
		//
		|| this.props.state !== nextProps.state
		|| this.props.config !== nextProps.config
		|| this.props.children !== nextProps.children
		|| this.props.onClick !== nextProps.onClick
		|| this.props.disabled !== nextProps.disabled
		|| this.props.style !== nextProps.style) {
			return true;
		}

		return false;
	}

	render() {
		const _this = this;
		const _state = this.state;
		const _props = this.props;
		// const _buttonState = _props.state ? _this.getState(_props.state) : null;

		if(_props.config && _state.current && _this.hasState(_state.current) && _this.getState(_state.current)) {
			/* Config and current State Exist */
			/* Load and display defined state */
			const _buttonState = _this.getState(_state.current);

			return (
				<button
					className={cx('ouStateButton', _buttonState.style, {
						default: !_buttonState.style,
						small: _props.small
					})}
					disabled={_props.disabled || false}
					style={_props.style || null}
					onClick={(event) => {
						if(_props.onClick) {
							_props.onClick(event, _this);
						}
					}}
				>
					{_buttonState.icon && (
						<i className={cx('ouStateButton__Icon', _buttonState.icon)} />
					)}
					{_buttonState.text && (
						<span className={cx('ouStateButton__Text', _buttonState.textClass)}>
							{_buttonState.icon && ' '}
							{_buttonState.text}
						</span>
					)}
				</button>
			)
		} else {
			/* No Config or State Defined */
			/* Simple parse normal button content if exists */
			return (
				<button
					className={'ouStateButton default undefined'}
					disabled={_props.disabled || false}
					style={_props.style || null}
					onClick={(event) => {
						if(_props.onClick) {
							_props.onClick(event, _this);
						}
					}}
				>
					{_props.children || null}
				</button>
			)
		}
	}
}

OuStateButton.propTypes = {
	// Current / Initial Button State
	state: PropTypes.string,
	// Button Config (view _exampleConfig)
	config: PropTypes.object,
	// JSX Child Components
	children: PropTypes.node,
	// Parse an onClick handler (event, button)
	onClick: PropTypes.func,
	// Disable Button
	disabled: PropTypes.bool,
	// Parse Additional Styles
	style: PropTypes.object
}

OuStateButton.defaultProps = {
	// None
}

// Example Config
// eslint-disable-next-line
const _exampleConfig = {
	default: {
		text: 'Hinzufügen',
		textClass: 'd-none d-lg-inline',
		icon: 'fa fa-plus-circle',
		style: 'success',
	},
	loading: {
		text: 'Hinzufügen',
		textClass: 'd-none d-lg-inline',
		icon: 'fa fa-refresh fa-spin fa-lg fa-fw',
		style: 'success'
	},
	success: {
		text: 'Hinzugefügt',
		textClass: 'd-none d-lg-inline',
		icon: 'fa fa-check',
		style: 'success'
	},
	error: {
		text: 'Fehlgeschlagen',
		textClass: 'd-none d-lg-inline',
		icon: 'fa fa-times',
		style: 'danger'
	},
	retry: {
		text: 'Wiederholen',
		textClass: 'd-none d-lg-inline',
		icon: 'fa fa-repeat',
		style: 'primary'
	}
};

export default OuStateButton;
