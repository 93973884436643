import React, { PureComponent } from 'react';
import styled from 'styled-components';

const OuBoxWrapper = styled.div.attrs({
	className: 'col-12 col-md-6 col-xl-3'
})`
	margin-bottom: 1rem;
`;

const OuBoxInner = styled.div.attrs({
})`
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: 1rem;
	color: #ffffff;
	background-color: #${process.env.REACT_APP_TARGET === 'smileys' ? 'd1060c' : 'e80c52'};
	cursor: pointer;

`;

const OuBoxContent = styled.a.attrs({
})`
	font-size: 1rem;
	user-select: none;
`;

class OuBox extends PureComponent {
	render() {
		const { onClick, children } = this.props;

		return (
			<OuBoxWrapper onClick={() => {
				onClick();
			}}>
				<OuBoxInner>
					<OuBoxContent>
						{children && children}
					</OuBoxContent>
				</OuBoxInner>
			</OuBoxWrapper>
		);
	}
}

export default OuBox;
