// React
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// Utils
import uuid from 'uuid/v1';
import { classnames as cx } from 'utils';
// Styles
import './OuCheckbox.css';

class OuCheckbox extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			id: ('ouCheckbox_' + uuid()),
			checked: this.props.checked || false
		}

	}

	render() {
		const _this = this;
		const _state = this.state;
		const _props = this.props;

		return (
			<div className={cx('ouCheckbox', _props.className, {
				checkbox: !_props.switch,
				switch: _props.switch,
				rounded: _props.rounded
			})}>
				<input
					className={cx('ouCheckbox__input')} type="checkbox"
					name={_state.id} id={_state.id} checked={_state.checked} readOnly
				/>
				<label className={cx('ouCheckbox__label', {
					muted: _props.muted
				})} htmlFor={_state.id} onClick={(event) => {
					const _newChecked = !_state.checked;

					// Update State
					_this.setState({
						checked: _newChecked
					});

					// Perform onChange
					if(_props.onChange) {
						_props.onChange(event, _newChecked);
					}
				}}>
					<span className={cx('ouCheckbox__box')} />
					{_props.label && (
						<span className={cx('ouCheckbox__text')}>{_props.label}</span>
					)}
				</label>
			</div>
		);
	}
}

OuCheckbox.defaultProps = {
	rounded: false,
	switch: false
}

OuCheckbox.propTypes = {
	checked: PropTypes.bool.isRequired,
	label: PropTypes.string,
	onChange: PropTypes.func,
	rounded: PropTypes.bool,
	switch: PropTypes.bool
}

export default OuCheckbox;
