import React from 'react';
import { Route, Redirect } from 'react-router-dom';
//
import pathToRegexp from 'path-to-regexp';

const OuRedirect = ({ exact, from, push, to }) => {
	return (
		<Route exact={exact} path={from} component={({ match: { params }, location: { search, state } }) => (
			<Redirect to={{
				pathname: pathToRegexp.compile(to)(params || {}),
				search: search || '',
				state: state || {}
			}} push={push} />
		)} />
	);
};

export default OuRedirect;
