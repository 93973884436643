const initialState = {
	hash: null,
	addressName: null,
	deliveryMode: null,
};

const store = (state = initialState, action) => {
	switch(action.type) {
		case 'STORE_SELECT': {
			const hasStore = !!action.store;

			return {
				hash: hasStore ? action.store.store_hash : null,
				addressName: hasStore ? action.store.store_address_name : null,
				deliveryMode: hasStore ? action.store.customer_area_mode : null,
			};
		}

		default: {
			return state;
		}
	}
}

export default store;
