// React
import React, { Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
// Custom Route
import { OuRoute } from 'components/parts/universal';
// Components
import OuNewsletterSelect from './OuNewsletterSelect';

// Components (Async)
const OuNewsletterOverview = React.lazy(() => import(
	/* webpackChunkName: 'ou_newsletter_overview' */
	'./overview/OuNewsletterOverview'
));

const OuNewsletter = () => (
	<Suspense fallback={<p>Lädt...</p>}>
		<Switch>
			<Redirect exact from='/newsletter/overview' to='/newsletter/overview/10/1' />
			<OuRoute title="Übersicht" path="/newsletter/overview/:max/:current" component={OuNewsletterOverview} />
			<OuRoute path="/newsletter/:hash" component={OuNewsletterSelect} />
			{/* No Match */}
			<Redirect from='/newsletter' to='/newsletter/overview/10/1' />
		</Switch>
	</Suspense>
);

export default OuNewsletter;
