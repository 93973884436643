// React
import React, { Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
// Custom Route
import { OuRoute, OuRedirect } from 'components/parts/universal';

// Components (Async)
const OuNewsletterRecipient = React.lazy(() => import(
	/* webpackChunkName: 'ou_newsletter_recipient' */
	'./recipient/OuNewsletterRecipient'
));
const OuNewsletterBlacklist = React.lazy(() => import(
	/* webpackChunkName: 'ou_newsletter_blacklist' */
	'./blacklist/OuNewsletterBlacklist'
));

const OuNewsletterSelect = () => (
	<Suspense fallback={<p>Lädt...</p>}>
		<Switch>
			<OuRedirect exact from='/newsletter/:hash' to='/newsletter/:hash/recipient/10/1' />
			<OuRedirect exact from='/newsletter/:hash/recipient' to='/newsletter/:hash/recipient/10/1' />
			<OuRedirect exact from='/newsletter/:hash/recipient/:max' to='/newsletter/:hash/recipient/:max/1' />
			<OuRoute title="Empfänger" path="/newsletter/:hash/recipient/:max/:current" component={OuNewsletterRecipient} />
			<OuRedirect exact from='/newsletter/:hash/blacklist' to='/newsletter/:hash/blacklist/10/1' />
			<OuRoute title="Blacklist" path="/newsletter/:hash/blacklist/:max/:current" component={OuNewsletterBlacklist} />
			{/* No Match */}
			<Redirect from='/store/:hash' to='/store/overview/10/1' />
		</Switch>
	</Suspense>
);

export default OuNewsletterSelect;
