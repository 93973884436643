// React
import React, { Component, Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
// Custom Route
import { OuRoute, OuRedirect } from 'components/parts/universal';

// Components (Async)
const OuCouponOverview = React.lazy(() => import(
	/* webpackChunkName: 'ou_coupon_overview' */
	'./overview/OuCouponOverview'
));

class OuCoupon extends Component {
	render() {
        return (
			<Suspense fallback={<p>Lädt...</p>}>
				<Switch>
					{/* Overview */}
					<OuRedirect exact from='/coupon/overview' to='/coupon/overview/10/1' />
					<OuRoute title="Übersicht" path="/coupon/overview/:max/:current" component={OuCouponOverview} />
					{/* No Match */}
					<Redirect from='/coupon' to='/coupon/overview/10/1' />
				</Switch>
			</Suspense>
        );
    }
}

export default OuCoupon;
