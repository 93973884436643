// React
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// Utils
import { classnames as cx } from 'utils';
// Redux
import { connect } from 'react-redux';
import { authRoute } from 'actions/auth';

class OuPills extends PureComponent {
	static propTypes = {
		pills: PropTypes.array.isRequired
	}

	static defaultProps = {
		pills: []
	}

	render() {
		const { props: { pills, router: { location }, authRoute } } = this;

		console.log('PILLS', this.props);

		//
		return (
			<nav className={cx('ouPills', 'nav nav-pills flex-column flex-sm-row')}>
				{pills.length > 0 && pills.map((p, i) => {
					return (
						<button className={cx('flex-sm-fill text-sm-center nav-link', {
							active: location.pathname.startsWith(p.url)
						})} key={i} onClick={() => {
							authRoute({
								...(p.props || {}),
								...location,
								pathname: p.url,
							});
						}}>{p.text || ''}</button>
					);
				})}
			</nav>
		);
    }
}


const mapStateToProps = (state) => {
	return {
		router: state.router
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		authRoute: (path) => dispatch(authRoute(path))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(OuPills);
