// React
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
// Utils
import { classnames as cx, blacklist as bx } from 'utils';
// Styles
import './OuSlider.css';

class OuSlider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// alerts: []
		}

		// Functions
		this.handleDrag = this.handleDrag.bind(this);
		this.handleDragEnd = this.handleDragEnd.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleMouseDown = this.handleMouseDown.bind(this);
		this.getPos = this.getPos.bind(this);
		this.getPosition = this.getPosition.bind(this);
		this.handleDrag = this.handleDrag.bind(this);
		this.handleDrag = this.handleDrag.bind(this);
	}

	handleDrag(event) {
		// event.preventDefault();
		this.handleChange(this.getPos(event));
	}

	handleDragEnd(event) {
		// event.preventDefault();
		document.removeEventListener('mousemove', this.handleDrag);
		document.removeEventListener('mouseup', this.handleDragEnd);

		document.removeEventListener('touchmove', this.handleDrag);
		document.removeEventListener('touchend', this.handleDragEnd);
		document.removeEventListener('touchcancel', this.handleDragEnd);

		if(this.props.onDragEnd) {
			this.props.onDragEnd();
		}
	}

	handleChange(position, dragEnd) {
		const _props = this.props;
		if(!_props.onChange) return;

		const _clientRect = findDOMNode(this).getBoundingClientRect();
		const { width, height } = _clientRect;
		const { axis } = _props;
		let { top, left } = position;

		if(left < 0) left = 0;
		if(left > width) left = width;
		if(top < 0) top = 0;
		if(top > height) top = height;

		let x = 0;
		let y = 0;
		if(axis === 'x' || axis === 'xy') {
			x = left / width * (this.props.xmax - this.props.xmin) + this.props.xmin;
		}
		if(axis === 'y' || axis === 'xy') {
			y = top / height * (this.props.ymax - this.props.ymin) + this.props.ymin;
		}

		_props.onChange({
			x: x,
			y: y
		});
	}

	handleMouseDown(event) {
		// event.preventDefault();
		const _sliderHandle = this.refs.handle;
		const _clientPosition = this.getClientPosition(event);

		this.start = {
			x: _sliderHandle.offsetLeft,
			y: _sliderHandle.offsetTop
		};

		this.offset = {
			x: _clientPosition.x,
			y: _clientPosition.y
		};

		// Mouse Events
		document.addEventListener('mousemove', this.handleDrag);
		document.addEventListener('mouseup', this.handleDragEnd);
		// Touch Events
		document.addEventListener('touchmove', this.handleDrag);
		document.addEventListener('touchend', this.handleDragEnd);
		document.addEventListener('touchcancel', this.handleDragEnd);
	}

	getPos(event) {
		const _clientPosition = this.getClientPosition(event);
		// const _clientRect = findDOMNode(this).getBoundingClientRect();
		const _positionX = _clientPosition.x + this.start.x - this.offset.x;
		const _positionY = _clientPosition.y + this.start.y - this.offset.y;

		return {
			left: _positionX,
			top: _positionY
		};
	}

	getPosition() {
		let top =
			(this.props.y - this.props.ymin) /
			(this.props.ymax - this.props.ymin) *
			100;
		let left =
			(this.props.x - this.props.xmin) /
			(this.props.xmax - this.props.xmin) *
			100;

		if(top > 100) top = 100;
		if(top < 0) top = 0;
		if(this.props.axis === 'x') top = 0;
		top += '%';

		if(left > 100) left = 100;
		if(left < 0) left = 0;
		if(this.props.axis === 'y') left = 0;
		left += '%';

		return {
			top: top,
			left: left
		};
	}

	getClientPosition(event) {
		const _touches = event.touches;
		if(_touches && _touches.length) {
			const _finger = _touches[0];
			return {
				x: _finger.clientX,
				y: _finger.clientY
			};
		}

		return {
			x: event.clientX,
			y: event.clientY
		};
	}

	render() {
		const _this = this;
		const _props = this.props;
		const { axis } = _props;
		const _filteredProps = bx(
			_props,
			'axis',
			'x',
			'y',
			'xmin',
			'xmax',
			'ymin',
			'ymax',
			'onChange',
			'onDragEnd',
			'className',
			'onClick'
		);

		// Get Position
		const _position = _this.getPosition();

		// Get Value Style / Size
		const _valueStyle = {};
		if(axis === 'x') _valueStyle.width = _position.left;
		if(axis === 'y') _valueStyle.height = _position.top;

		return (
			<div className={cx('ouSlider', axis, this.props.className)} {..._filteredProps} onClick={(event) => {
				const _clientPosition = _this.getClientPosition(event);
				const _clientRect = findDOMNode(_this).getBoundingClientRect();

				_this.handleChange({
					top: _clientPosition.y - _clientRect.top,
					left: _clientPosition.x - _clientRect.left
				}, true);
			}}>
				<div
					className={'ouSlider__value'}
					style={_valueStyle}
				/>
				<div
					className={'ouSlider__handle'} ref={'handle'}
					onTouchStart={_this.handleMouseDown}
					onMouseDown={_this.handleMouseDown}
					onClick={(event) => {
						event.stopPropagation();
						event.nativeEvent.stopImmediatePropagation();
					}}
					style={_position}
				/>
			</div>
		)
	}
}

OuSlider.propTypes = {
	axis: PropTypes.string,
    x: PropTypes.number,
    xmax: PropTypes.number,
    xmin: PropTypes.number,
    y: PropTypes.number,
    ymax: PropTypes.number,
    ymin: PropTypes.number

	// position: PropTypes.oneOf([
	// 	't-l', 't-c', 't-r', 'c-l', 'c-c', 'c-r', 'b-l', 'b-c', 'b-r'
	// ]),
	// wrapper: PropTypes.string
}

OuSlider.defaultProps = {
	axis: 'x',
	xmin: 0,
	xmax: 0
}

export default OuSlider;
