// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Styles
import './OuCurrencyInput.css';

class OuCurrencyInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: this.props.inital ? parseInt(String(this.props.inital).replace(/[^0-9-]/g, ''), 10) : 0,
			floatValue: 0.0,
			maskedValue: '0,00'
		}

		this.generateValues = this.generateValues.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		if(this.props.inital) {
			const { value, floatValue, maskedValue } = this.generateValues(this.props.inital, true);

			this.setState({
				value, floatValue, maskedValue
			});
		}
	}

	generateValues(value, inital = false) {
		let _value = '';
		if(value) {
			if(inital) {
				_value = String(parseInt(String(value * 100).replace(/[^0-9-]/g, ''), 10));
			} else {
				_value = String(parseInt(String(value).replace(/[^0-9-]/g, ''), 10));
			}
		}

		let _maskedValue = _value;
		if(_value.length > 2) {
			_maskedValue = [_value.slice(0, _value.length - 2), ',', _value.slice(_value.length - 2)].join('');
		} else {
			if(_value.length === 2) {
				_maskedValue = '0,' + _value;
			} else if(_value.length === 1) {
				_maskedValue = '0,0' + _value;
			} else {
				_value = 0;
				_maskedValue = '0,00';
			}
		}

		return {
			value: parseInt(_value, 10),
			floatValue: parseFloat(_maskedValue.replace(',', '.')),
			maskedValue: _maskedValue
		};
	}

	handleChange(event) {
		event.preventDefault();
		const { value, floatValue, maskedValue } = this.generateValues(event.target.value);

		this.setState({
			value, floatValue, maskedValue
		});

		if(this.props.onChange) {
			// Perform onChange if exists
			this.props.onChange(event, floatValue);
		}
	}

	render() {
		const { inputProps } = this.props;
		const { value, maskedValue } = this.state;
		const { handleChange } = this;

		return (
			<div className={'ouCurrencyInput'}>
				<div className={'ouCurrencyInput__display'}>{maskedValue}</div>
				<input className={'ouCurrencyInput__input'} {...inputProps} value={value} onChange={handleChange} />
			</div>
		);
	}
}

OuCurrencyInput.propTypes = {
	inital: PropTypes.any,
	onChange: PropTypes.func
}

export default OuCurrencyInput;
