// React
import React, { PureComponent } from 'react';
// prop types TODO
// Components
import {  OuSlider } from 'components/parts/universal';
// Utils
import uuid from 'uuid/v1';
import moment from 'moment';
import { classnames as cx } from 'utils';
// Styles
import './OuTimepicker.css';

class OuTimepicker extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			rootClass: ('ouTimepicker__' + uuid()),
			open: false,
			changed: null,
			time: this.props.time || null
		}

		this.handleGlobalClick = this.handleGlobalClick.bind(this);
	}

	UNSAFE_componentWillMount() {
		document.addEventListener('click', this.handleGlobalClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleGlobalClick, false);
	}

	// Global Click Handler
	handleGlobalClick(event) {
		const _root = this.state.rootClass;
		const _classList = event.target.classList;
		if(!_classList.contains(_root)) {
			// Check If Timepicker was Clicked
			if(!event.target.parentElement
			|| !event.target.parentElement.classList.contains(_root)) {
				this.setState({
					open: false,
					changed: null
				});
			}
		}
	}

	render() {
		const _this = this;
		const _state = this.state;
		const _props = this.props;
		const _root = _state.rootClass;

		// Current / Inital Moment Instance
		const _moment = _state.time;

		if(_moment) {
			return (
				<div className={cx(_root, 'ouTimepicker', _props.className, {
					open: _state.open
				})} style={_props.style || null}>
					<input
						readOnly
						className={cx(_root, 'form-control')}
						value={(_state.open && _state.changed) ? _state.changed.format('HH:mm:ss') : _moment.format('HH:mm:ss')}
						onChange={(event) => {}}
						onClick={(event) => {
							_this.setState({
								open: true
							});
						}}
					/>
					{_state.open && (
						<div className={cx(_root, 'ouTimepicker__popover', `open-${_props.openDirection}`, `float-${_props.floatDirection}`)}>
							<div className={cx(_root, 'ouTimepicker__sliders')}>
								<div className={cx(_root, 'ouTimepicker__text')}>Stunden:</div>
								<OuSlider
									className={cx(_root, 'ouTimepicker__slider', 'hours')}
									xmin={0}
									xmax={23}
									x={_state.changed ? _state.changed.hour() : _moment.hour()}
									onChange={(position) => {
										const _changed = (_state.changed
											? moment(_state.changed ).hours(parseInt(position.x, 10))
											: moment(_moment).hours(parseInt(position.x, 10))
										);

										_this.setState({
											changed: _changed
										});

										if(_props.onTimeChange) _props.onTimeChange(_changed);
									}}
								/>
								<div className={cx(_root, 'ouTimepicker__text')}>Minuten:</div>
								<OuSlider
									className={cx(_root, 'ouTimepicker__slider', 'minutes')}
									xmin={0}
									xmax={59}
									x={_state.changed ? _state.changed.minute() : _moment.minute()}
									onChange={(position) => {
										const _changed = (_state.changed
											? moment(_state.changed ).minutes(parseInt(position.x, 10))
											: moment(_moment).minutes(parseInt(position.x, 10))
										);

										_this.setState({
											changed: _changed
										});

										if(_props.onTimeChange) _props.onTimeChange(_changed);
									}}
								/>
								{_props.showSeconds && [
									<div key="editSecondsTitle" className={cx(_root, 'ouTimepicker__text')}>Sekunden:</div>,
									<OuSlider
										key="editSecondsSlider"
										className={cx(_root, 'ouTimepicker__slider', 'seconds')}
										xmin={0}
										xmax={59}
										x={_state.changed ? _state.changed.seconds() : _moment.seconds()}
										onChange={(position) => {
											const _changed = (_state.changed
												? moment(_state.changed ).seconds(parseInt(position.x, 10))
												: moment(_moment).seconds(parseInt(position.x, 10))
											);

											_this.setState({
												changed: _changed
											});

											if(_props.onTimeChange) _props.onTimeChange(_changed);
										}}
									/>
								]}
							</div>
							<div className={cx(_root, 'ouTimepicker__buttons')}>
								<button className={cx(_root, 'ouTimepicker__button', 'cancel')} onClick={(event) => {
									_this.setState({
										open: false,
										changed: null
									});
								}}>
									<i className="fa fa-times" />
								</button>
								<button
									className={cx(_root, 'ouTimepicker__button', 'save')}
									onClick={(event) => {
										_this.setState({
											open: false,
											changed: null,
											time: _state.changed
										});

										if(_props.onTimeSave) _props.onTimeSave(_state.changed);
									}}
									disabled={!_state.changed || _state.changed === _state.time}
								>
									<i className="fa fa-check" />
								</button>
							</div>
						</div>
					)}
				</div>
			)
		} else return null;
	}
}

OuTimepicker.defaultProps = {
	openDirection: 'down',
	floatDirection: 'left',
	showSeconds: false
}

// Prop Types

export default OuTimepicker;
