//  React
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
// React Router
import { NavLink } from 'react-router-dom';
// Utils
import { crumbStore } from './utils/store';

class OuBreadcrumbs extends PureComponent {
	static propTypes = {
		className: PropTypes.string,
		children: PropTypes.oneOfType([
			PropTypes.node,
			PropTypes.arrayOf(
				PropTypes.node
			)
		])
	}

	static defaultProps = {
		className: 'ouBreadcrumb',
		children: null
	}

	UNSAFE_componentWillMount() {
		this._unsubscribe = crumbStore.subscribe(() => {
			this.forceUpdate();
		})
	}

	componentWillUnmount() {
		this._unsubscribe();
	}

	render() {
		const { className, children, router: { location } } = this.props;
        // Get current Breadcrumbs
		const crumbs = crumbStore.getState();

		return (
			<div className={className}>
				<nav className={className + '__Wrapper'}>
					<div className={className + '__Wrapper--inner'}>
						{crumbs.map((crumb, i) => (
							<span key={crumb.id || i} className={className + '__Crumb'}>
								{crumb.disabled ? (
									<span className={className + '__Crumb--inner disabled'}>{crumb.title}</span>
								) : (
									<NavLink
										exact
										className={className + '__Crumb--inner'}
										to={{
											pathname: crumb.pathname,
											search: crumb.search,
											state: {
												...location.state,
												...crumb.state
											}
										}}
									>
										{crumb.title}
									</NavLink>
								)}
							</span>
						))}
					</div>
				</nav>
				{children}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
        router: state.router
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
        //
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(OuBreadcrumbs);
