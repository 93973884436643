// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import { authRoute } from '../../../actions/auth';

class OuBreadcrumb extends Component {
	render() {
		const _this = this;

        return (
            <ol className={'ouBreadcrumb breadcrumb'}>
                {_this.props.base_href ? (
					<span className={'ouBreadcrumbItem breadcrumb-item'}>
						<button className={'ouBreadcrumbLink breadcrumb-link'} onClick={() => {
							_this.props.authRoute(_this.props.base_href);
						}}>{_this.props.base_text}</button>
					</span>
				) : (
					<span className={'ouBreadcrumbItem breadcrumb-item'}>
						{_this.props.base_text}
					</span>
				)}
                {_this.props.next_text ? (
					<span className={'ouBreadcrumbItem breadcrumb-item'}>
						{_this.props.next_text}
					</span>
				) : null}
            </ol>
        )
    }
}

OuBreadcrumb.propTypes = {
    children: PropTypes.node
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch) => {
	return {
		authRoute: (path) => {
			dispatch(authRoute(path));
		}
	}
}

const OuBreadcrumbMapped = connect(mapStateToProps, mapDispatchToProps)(OuBreadcrumb);
export default OuBreadcrumbMapped;
