import { parseLocale } from './locale';
import classnames from './classnames';
import blacklist from './blacklist';
import md5 from './md5';

const mapObject = (object, callback) => {
	return Object.keys(object).map((key) => {
		return callback(key, object[key]);
	});
}

const getErrorCode = (jsonResponse) => {
	if(jsonResponse.message) {
		return jsonResponse.message.replace(/(^.*\[|\].*$)/g, '');
	} else {
		return 'OU-0-0000';
	}
}

export {
	parseLocale,
	//
	classnames,
	blacklist,
	md5,
	//
	mapObject,
	getErrorCode
}
