// React / Router
import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Components (Async)
const OuBasic = React.lazy(() => import('./OuBasic'));

const OuStats = () => (
	<Suspense fallback={<p>Lädt...</p>}>
		<Switch>
			<Redirect exact from='/stats' to='/dashboard' />
			<Route path="/stats/basic/:type/:display" component={OuBasic} />
			{/* No Match */}
			<Redirect from='/' to='/dashboard' />
		</Switch>
	</Suspense>
);

export default OuStats;
