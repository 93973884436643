// React
import React from 'react';

const OuPanel = ({ type, children, ...spareProps }) => (
	<div className={'panel panel-' + (type|| 'default')} {...spareProps}>
		{children || null}
	</div>
);

export default OuPanel;
