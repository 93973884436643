// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OuPanelTitle extends Component {
	shouldComponentUpdate(nextProps, nextState) {
		if(this.props.children !== nextProps.children
		|| this.props.style !== nextProps.style) {
			return true;
		}

		return false;
	}

	render() {
        const _this = this;

        return (
            <div className={'panel-title'} style={_this.props.style || null}>
				{_this.props.children || null}
            </div>
        )
    }
}

OuPanelTitle.propTypes = {
    children: PropTypes.node
}

export default OuPanelTitle;
