// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OuPagination extends Component {
	render() {
		const _this = this;
		const maxItems = parseInt(_this.props.maxItems, 10);
		const totalItems = parseInt(_this.props.totalItems, 10);
		const currentPage = parseInt(_this.props.currentPage, 10);
		const displaySize = parseInt(_this.props.displaySize, 10);

		// Pagination Size
		let difference = 0;
		if(displaySize % 2 === 0) difference = (displaySize / 2);
		else difference = ((displaySize - 1) / 2);

		// Total Pages
		const pages = [];
		for(let i = 1; i <= Math.ceil(totalItems / maxItems); i++) pages.push(i);

		return (
			<div className="ouPaginationWrapper">
				{pages.length > 1 && (
					<ul className="ouPagination pagination justify-content-center">
						{pages.length > 25 && (
							<li className={'ouPaginationItem page-item' + (currentPage <= 1 ? ' disabled' : '')}>
								<button className="ouPaginationLink page-link" onClick={() => {
									_this.props.handleClick((currentPage - 10) < 1 ? 1 : currentPage - 10);
								}} disabled={currentPage <= 1}>
									<i className="fa fa-chevron-left" aria-hidden="true" style={{marginRight: '-4px'}} />
									<i className="fa fa-chevron-left" aria-hidden="true" />
									<span className="sr-only">10 Zurück</span>
								</button>
							</li>
						)}
						<li className={'ouPaginationItem page-item' + (currentPage <= 1 ? ' disabled' : '')}>
							<button className="ouPaginationLink page-link" onClick={() => {
								_this.props.handleClick(currentPage - 1);
							}} disabled={currentPage <= 1}>
								<i className="fa fa-chevron-left" aria-hidden="true" />
								<span className="sr-only">Zurück</span>
							</button>
						</li>
						{pages.map((page) => {
							if((page >= (currentPage - difference) || page > (pages.length - displaySize)) && (page <= (currentPage + difference) || page <= displaySize)) {
								return (
									<li key={'page_' + page} className={'ouPaginationItem page-item' + (currentPage === page ? ' active' : '')}>
										<button className="ouPaginationLink page-link" onClick={() => {
											_this.props.handleClick(page);
										}}>{page}</button>
									</li>
								)
							} else {
								return (
									null
								)
							}
						})}
						<li className={'ouPaginationItem page-item' + (currentPage === pages.length ? ' disabled' : '')}>
							<button className="ouPaginationLink page-link" onClick={() => {
								_this.props.handleClick(currentPage + 1);
							}} disabled={currentPage === pages.length}>
								<i className="fa fa-chevron-right" aria-hidden="true" />
								<span className="sr-only">Weiter</span>
							</button>
						</li>
						{pages.length > 25 && (
							<li className={'ouPaginationItem page-item' + (currentPage === pages.length ? ' disabled' : '')}>
								<button className="ouPaginationLink page-link" onClick={() => {
									_this.props.handleClick((currentPage + 10) > pages.length ? pages.length : currentPage + 10);
								}} disabled={currentPage === pages.length}>
									<i className="fa fa-chevron-right" aria-hidden="true" />
									<i className="fa fa-chevron-right" aria-hidden="true" style={{marginLeft: '-4px'}} />
									<span className="sr-only">10 Weiter</span>
								</button>
							</li>
						)}
					</ul>
				)}
			</div>
		)
	}
}

OuPagination.defaultProps = {
	maxItems: 10,
	totalItems: 0,
	currentPage: 1,
	displaySize: 5
}

OuPagination.propTypes = {
	handleClick: PropTypes.func.isRequired,
	maxItems: PropTypes.any.isRequired,
	totalItems: PropTypes.any.isRequired,
	currentPage: PropTypes.any.isRequired,
	displaySize: PropTypes.any
}

export default OuPagination;
