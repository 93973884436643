// React
import React, { Component } from 'react';
// Redux
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
// HTTP
import axios from 'axios';
import { BASE_URL } from 'config/api';
// Components
import Logo from 'assets/img/logo.svg';
import LogoSmileys from 'assets/img/___/smileys/logo.png';

class OuForgot extends Component {
	constructor(props) {
		super(props);

		this.state = {
			username: '',
			running: false,
			last: null,
			success: false
		}

		this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
        const value =  event.target.value;
        const name =  event.target.name;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        event.preventDefault();
		const _this = this;
		const _user = this.state.username;

		/* Spam Prevention */
		if(_this.state.last !== null) {
			if(!(_this.state.last < (Date.now() - 2000))) {
				return;
			}
		}
		_this.setState({
			last: Date.now(),
			running: true
		});

		axios.post(`${BASE_URL}/user/auth/forgot`, {
			user_name: _user
		})
		.catch((error) => {
			console.error(error);
		});

		setTimeout(function () {
			_this.setState({
				running: false,
				success: true
			});
		}, 2500);
    }

    render() {
		const _this = this;

        return (
            <div className="ouPage pageAuth pageForgot">
				<div className="ouAuth--wrapper">
					<div className="ouAuth--grid">
						<div className="ouAuth--card">
							<form className="ouAuth--form" onSubmit={_this.handleSubmit} autoComplete="off" >
								<img
									className="ouAuth--logo"
									src={process.env.REACT_APP_TARGET === 'smileys' ? LogoSmileys : Logo}
									alt={process.env.REACT_APP_TARGET === 'smileys' ? 'Smiley´s Pizza Profis' : 'ORDERU - Das Shopsystem'}
								/>
								<h3 className="ouAuth--headline">Passwort anfordern</h3>
								<div className="ouAuth--form--field">
									<label htmlFor="username">
										<span className="ouAuth--icon"><i className="fa fa-user" /></span>
										<span className="sr-only">E-Mail Adresse</span>
									</label>
									<input className="ouAuth--form--input" type="text" name="username" placeholder="E-Mail Adresse" value={_this.state.username} onChange={_this.handleChange} disabled={_this.state.running || _this.state.success} />
								</div>
								<div className="ouAuth--form--field">
									{_this.state.running ? (
										<button className="ouAuth--submit" type="submit" disabled>
											<i className="fa fa-refresh fa-spin fa-fw" />
											{' '}<span>Anfordern</span>
										</button>
									) : (
										<button className={'ouAuth--submit' + (_this.state.success ? ' success' : '')} type="submit" disabled={
											_this.state.success || _this.state.username.length < 5
										}>
											{_this.state.success ? (
												<div>
													<i className="fa fa-check fa-fw" />
													{' '}<span>Angefordert</span>
												</div>
											) : (
												<span>Anfordern</span>
											)}
										</button>
									)}
								</div>
							</form>
						</div>

						<p className="ouAuth--forgot">
							<button onClick={() => {
								_this.props.pushRouter('/login');
							}}>Zum Login</button>
						</p>
					</div>
				</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch) => {
	return {
		pushRouter: (path) => {
			dispatch(push(path));
		}
	}
}

const OuForgotMapped = connect(mapStateToProps, mapDispatchToProps)(OuForgot);
export default OuForgotMapped;
