// React
import React from 'react';
import ReactDOM from 'react-dom';
// CRA
import registerServiceWorker from 'registerServiceWorker';
// Redux
import { createStore, compose, applyMiddleware } from 'redux';
import { Provider } from 'react-redux'
import { createRootReducer } from 'reducers';
import thunkMiddleware from 'redux-thunk';
// React Router
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';

// Components
import OuApp from 'OuApp';

//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//-//

export const history = createBrowserHistory();

// Compose with Redux Dev-Tool
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
	createRootReducer(history),
	composeEnhancer(applyMiddleware(
		routerMiddleware(history),
		thunkMiddleware,
	)),
);

ReactDOM.render(
	<Provider store={store}>
		<Router>
			<ConnectedRouter history={history}>
				<OuApp />
			</ConnectedRouter>
		</Router>
	</Provider>,
    document.getElementById('root')
);

registerServiceWorker();

let textStyle = '';
if(process.env.REACT_APP_TARGET === 'smileys') {
	console.log('%cSmileys - Die Pizza Profis', 'font-size: 32px; color:#e40e16; font-weight:bold;');
	textStyle = 'font-size: 16px; color:#6c4233; font-weight:bold;';
} else {
	console.log('%cORDERU - Das Shopsystem', 'font-size: 32px; color:#e80c52; font-weight:bold;');
	textStyle = 'font-size: 16px; color:#a86415; font-weight:bold;';
}
console.log('%c\nDu interessiert dich für Code? Schau lieber hier vorbei:\nhttps://www.elbwindmedia.de/jobs.html\n\n', textStyle);
if(process.env.REACT_APP_TARGET === 'smileys') {
	console.log('%cWeb: https://www.elbwindmedia.de/\nE-Mail: info@elbwindmedia.de\nTelefon: 040 - 605905320', textStyle);
} else {
	console.log('%cWeb: https://www.das-shopsystem.de/\nE-Mail: info@orderu.de\nTelefon: 040 - 609409870', textStyle);
}
