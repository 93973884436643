// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';
// Utils
import uuid from 'uuid/v1';
// Components
import OuAlertContainer from './OuAlertContainer';
import OuAlertMessage from './OuAlertMessage';
// Styles
import './OuAlert.css';

class OuAlert extends Component {
	constructor(props) {
		super(props);

		this.state = {
			alerts: []
		}

		this.show = this.show.bind(this);
		this.removeAlert = this.removeAlert.bind(this);
		this.clear = this.clear.bind(this);
	}

	show(config) {
		const alertId = uuid();
		config.id = alertId;
		const alert = {
			id: alertId,
			config: config
		};

		const newAlerts = this.state.alerts;
		newAlerts.unshift(alert);
		this.setState({
			alerts: newAlerts
		});
	}

	removeAlert(id) {
		this.setState({
			alerts: this.state.alerts.filter((alert) => {
				return alert.id !== id
			})
		});
	}

	clear() {
		this.setState({
			alerts: []
		});
	}

	render() {
		const _this = this;
		const _alerts = this.state.alerts;

		return (
			<OuAlertContainer position={_this.props.position} wrapper={_this.props.wrapper}>
				{_alerts.length > 0 ? (
					<CSSTransitionGroup transitionName={'scale'} transitionEnterTimeout={250} transitionLeaveTimeout={250}>
						{_alerts.map((alert) => {
							return (
								<OuAlertMessage key={'ouAlert_' + alert.id} config={alert.config} onClose={_this.removeAlert} />
							)
						})}
					</CSSTransitionGroup>
				) : null}
			</OuAlertContainer>
		)
	}
}

OuAlert.propTypes = {
	position: PropTypes.oneOf([
		't-l', 't-c', 't-r', 'c-l', 'c-c', 'c-r', 'b-l', 'b-c', 'b-r'
	]),
	wrapper: PropTypes.string
}

OuAlert.defaultProps = {
	position: 't-r',
	wrapper: '1rem'
}

export default OuAlert;
