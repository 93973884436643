// Redux
import { combineReducers } from 'redux';
// Reducers
import { connectRouter } from 'connected-react-router';
import authReducer from './auth';
import storeReducer from './store';
import { generatePaginatedReducer } from './paginated';
import googleMapsReducer from './google/maps';

export const createRootReducer = (history) => combineReducers({
	router: connectRouter(history),
	auth: authReducer,
	store: storeReducer,
	paginatedCoupon: generatePaginatedReducer('OU_COUPON', {
		inital: {
			sorted: [
				{ id: 'coupon_created', desc: true },
			],
		},
	}),
	paginatedStore: generatePaginatedReducer('OU_STORE'),
	paginatedStoreArea: generatePaginatedReducer('OU_STORE_AREA'),
	paginatedEnduser: generatePaginatedReducer('OU_ENDUSER'),
	paginatedNewsletter: generatePaginatedReducer('OU_NEWSLETTER'),
	paginatedNewsletterRecipient: generatePaginatedReducer('OU_NEWSLETTER_RECIPIENT'),
	paginatedNewsletterRecipientBlacklist: generatePaginatedReducer('OU_NEWSLETTER_RECIPIENT_BLACKLIST'),
	// Google Maps Instance
	googleMaps: googleMapsReducer
});
