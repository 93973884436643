// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OuAlertMessage extends Component {
	constructor(props) {
		super(props);

		this.closeSelf = this.closeSelf.bind(this);
	}

	componentDidMount() {
		const _conf = this.props.config;
		if(_conf.timeout) {
			setTimeout(() => {
				this.closeSelf();
			}, _conf.timeout);
		}
	}

	closeSelf() {
		const _conf = this.props.config;
		this.props.onClose(_conf.id);
	}

	render() {
		const _this = this;
		const _icon = ' fa fa-times';
		const _conf = this.props.config;
		const _type = (_conf.type ? _conf.type : 'default')

		return (
			<div className={'ouAlert__message ouAlert__message--' + _type}>
				{_conf.message ? (
					<span className={'ouAlert__message--text'}>{_conf.message}</span>
				) : (
					<div className={'ouAlert__message--inner--wrapper'}>
						 {_conf.inner ? (
							 <div className={'ouAlert__message--inner'} dangerouslySetInnerHTML={{__html: _conf.inner}} />
						 ) : (
							 <span className={'ouAlert__message--inner--error'}>Error.</span>
						 )}
					</div>
				)}
				<span className={'ouAlert__message--close' + _icon} onClick={_this.closeSelf} />
			</div>
		)
	}
}

OuAlertMessage.propTypes = {
	config: PropTypes.object.isRequired,
	onClose: PropTypes.func
}

OuAlertMessage.defaultProps = {
	onClose: () => {}
}

export default OuAlertMessage;
