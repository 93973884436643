const initialState = null;

export default (state = initialState, action) => {
	switch(action.type) {
		case 'OU_GOOGLE_MAPS_BIND':
			return action.instance;
		default:
			return state;
	}
}
