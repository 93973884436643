// React / Router
import React, { Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
// Custom Route
import { OuRoute } from 'components/parts/universal';

// Components (Async)
const OuEnduserOverview = React.lazy(() => import(
	/* webpackChunkName: 'ou_enduser_overview' */
	'./overview/OuEnduserOverview'
));
const OuEnduserDetail = React.lazy(() => import(
	/* webpackChunkName: 'ou_enduser_detail' */
	'./detail/OuEnduserDetail'
));

const OuEnduser = () => (
	<Suspense fallback={<p>Lädt...</p>}>
		<Switch>
			<OuRoute title="Übersicht" path="/enduser/overview/:max/:current" component={OuEnduserOverview} />
			<OuRoute title="Details" path="/enduser/detail/:hash" component={OuEnduserDetail} />
			{/* No Match */}
			<Redirect from='/enduser' to='/enduser/overview/10/1' />
		</Switch>
	</Suspense>
);

export default OuEnduser;
