import { push } from 'connected-react-router';
//
import axios from 'axios';
import { BASE_URL } from 'config/api';

export function authLogin(token) {
	return (dispatch) => {
		localStorage.setItem('x-token', token);
		axios.defaults.headers.common['x-token'] = `Bearer ${token}`;
		dispatch({ type: 'AUTH_LOGIN', authenticated: true, token: token });
	}
}

export function authLogout() {
	return (dispatch) => {
		localStorage.removeItem('x-token');
		delete axios.defaults.headers.common['x-token'];
		dispatch({type: 'AUTH_LOGOUT', authenticated: false, token: null});
		dispatch(push('/login'));
	}
}

export function authCheck() {
	return (dispatch) => {
		const localToken = localStorage.getItem('x-token');
		if(localToken) {
			axios.defaults.headers.common['x-token'] = `Bearer ${localToken}`;
			dispatch({type: 'AUTH_LOAD', authenticated: true, token: localToken});
			axios.post(`${BASE_URL}/user/auth/check`)
	        .then(response => response.data)
	        .then(json => {
	            if(json && json.status === true) {
					dispatch({type: 'AUTH_CHECK', authenticated: true, token: localToken});
	            } else {
					localStorage.removeItem('x-token');
					delete axios.defaults.headers.common['x-token'];
					dispatch({type: 'AUTH_CHECK', authenticated: false, token: null});
					dispatch(push('/login'));
				}
	        })
	        .catch((error) => {
				console.error(error);
				localStorage.removeItem('x-token');
				delete axios.defaults.headers.common['x-token'];
				dispatch({type: 'AUTH_CHECK', authenticated: false, token: null});
				dispatch(push('/login'));
	        });
		} else {
			localStorage.removeItem('x-token');
			delete axios.defaults.headers.common['x-token'];
			dispatch({type: 'AUTH_CHECK', authenticated: false, token: null});
			dispatch(push('/login'));
		}
	}
}

export function authRoute(path, persist = false) {
	return (dispatch, getState) => {
		dispatch({type: 'AUTH_ROUTE', path: path});
		const localToken = localStorage.getItem('x-token');
		if(localToken) {
			axios.defaults.headers.common['x-token'] = `Bearer ${localToken}`;
			dispatch({type: 'AUTH_LOAD', authenticated: true, token: localToken});
			axios.post(`${BASE_URL}/user/auth/check`)
	        .then(response => response.data)
	        .then(json => {
	            if(json && json.status === true) {
					dispatch({type: 'AUTH_CHECK', authenticated: true, token: localToken});

					// Build next Location
					let _nextLocation = typeof path === 'object' ? path : { pathname: path };

					let _persistedLocation = {};
					if(persist) _persistedLocation = getState().router.location;

					dispatch(push({
						..._persistedLocation,
						..._nextLocation
					}));
	            } else {
					localStorage.removeItem('x-token');
					delete axios.defaults.headers.common['x-token'];
					dispatch({type: 'AUTH_CHECK', authenticated: false, token: null});
					dispatch(push('/login'));
				}
	        })
	        .catch((error) => {
				console.error(error);
				localStorage.removeItem('x-token');
				delete axios.defaults.headers.common['x-token'];
				dispatch({type: 'AUTH_CHECK', authenticated: false, token: null});
				dispatch(push('/login'));
	        });
		} else {
			localStorage.removeItem('x-token');
			delete axios.defaults.headers.common['x-token'];
			dispatch({type: 'AUTH_CHECK', authenticated: false, token: null});
			dispatch(push('/login'));
		}
	}
}
