// React / OuRouter
import React, { PureComponent, Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
// Custom Route
import { OuRoute } from 'components/parts/universal';

// Components (Async)
const OuOverview = React.lazy(() => import(
	/* webpackChunkName: 'ou_admin_overview' */
	'./overview/OuOverview'
));
const OuAdminUser = React.lazy(() => import(
	/* webpackChunkName: 'ou_admin_user' */
	'./user/OuAdminUser'
));
const OuAdminCustomer = React.lazy(() => import(
	/* webpackChunkName: 'ou_admin_customer' */
	'./customer/OuAdminCustomer'
));
const OuAdminEnduser = React.lazy(() => import(
	/* webpackChunkName: 'ou_admin_enduser' */
	'./enduser/OuAdminEnduser'
));
const OuAdminStore= React.lazy(() => import(
	/* webpackChunkName: 'ou_admin_store' */
	'./store/OuAdminStore'
));
const OuAdminNewsletter = React.lazy(() => import(
	/* webpackChunkName: 'ou_admin_newsletter' */
	'./newsletter/OuAdminNewsletter'
));

class OuAdmin extends PureComponent {
    render() {
        return (
			<Suspense fallback={<p>Lädt...</p>}>
				<Switch>
					<OuRoute title="Übersicht" path="/admin/overview" component={OuOverview} />
					<OuRoute title="Benutzer" path="/admin/user" component={OuAdminUser} />
					<OuRoute title="Kunden" path="/admin/customer" component={OuAdminCustomer} />
					<OuRoute title="Newsletter" path="/admin/newsletter" component={OuAdminNewsletter} />
					<OuRoute title="Stores" path="/admin/store" component={OuAdminStore} />
					<OuRoute title="Benutzerkonten" path="/admin/enduser" component={OuAdminEnduser} />
					{/* No Match */}
					<Redirect from='/' to='/admin/overview' />
				</Switch>
			</Suspense>
        );
    }
}

export default OuAdmin;
