// React / Router
import React, { PureComponent, Fragment } from 'react';
// Redux
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { authCheck, authRoute } from 'actions/auth';
// Components
import { OuPanel, OuPanelHeading, OuPanelTitle, /* OuPanelBody */ } from 'components/parts/panel';
import { OuBox } from 'components/pages/admin/overview/styled';

class OuDashboard extends PureComponent {
	UNSAFE_componentWillMount() {
		const _props = this.props;

		_props.authCheck();
    }

    render() {
		const _this = this;
		const _auth = this.props.authState;
		const { pushRoute } = _this.props;

        return (
            <div className="ouPage pageDashboard">
				<div className="container-fluid">
					<div className="row" style={{marginBottom: '1.25rem'}}>
						<div className="col-12">
							<OuPanel>
								<OuPanelHeading>
									<OuPanelTitle style={{textAlign: 'center'}}>
										<h4>
											{process.env.REACT_APP_TARGET === 'smileys'
											? 'Smiley´s Pizza Profis'
											: 'ORDERU - Das Shopsystem'}
										</h4>
									</OuPanelTitle>
								</OuPanelHeading>
							</OuPanel>
						</div>
						{process.env.REACT_APP_TARGET !== 'smileys' ? (
							<Fragment>
								{_auth.hasPermission('STORE', 'GET') && _auth.hasPermission('STORE_ACCESS', 'ALL') && (
									<OuBox onClick={() => {
										pushRoute('/store/overview/10/1');
									}}>
										Stores
									</OuBox>
								)}
								{_auth.hasPermission('COUPON', 'GET') && (
									<OuBox onClick={() => {
										pushRoute('/coupon/overview/10/1');
									}}>
										Gutscheine
									</OuBox>
								)}
								{_auth.hasPermission('ENDUSER', 'GET') && (
									<OuBox onClick={() => {
										pushRoute('/enduser/overview/10/1');
									}}>
										Benutzerkonten
									</OuBox>
								)}
								{_auth.hasPermission('NEWSLETTER', 'GET') && (
									<OuBox onClick={() => {
										pushRoute('/newsletter/overview/10/1');
									}}>
										Newsletter
									</OuBox>
								)}
							</Fragment>
						) : (
							<Fragment>
								{_auth.hasPermission('STORE', 'GET') && (
									<OuBox onClick={() => {
										pushRoute('/store/overview/10/1');
									}}>
										Stores
									</OuBox>
								)}
								{_auth.hasPermission('COUPON', 'GET') && (
									<OuBox onClick={() => {
										pushRoute('/coupon/overview/10/1');
									}}>
										Gutscheine
									</OuBox>
								)}
							</Fragment>
						)}
					</div>
				</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
	return {
		authState: state.auth
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		authCheck: () => {
			dispatch(authCheck());
		},
		authRoute: (path) => {
			dispatch(authRoute(path));
		},
		pushRoute: (path) => {
			dispatch(push(path));
		}
	}
}

const OuDashboardMapped = connect(mapStateToProps, mapDispatchToProps)(OuDashboard);
export default OuDashboardMapped;
