// React
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// Utils
import uuid from 'uuid/v1';
import update from 'immutability-helper';
import { classnames as cx } from 'utils';
// Styles
import './OuSelect.css';

class OuSelect extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			rootClass: ('ouDropdownSelect_' + uuid()),
			selected: this.props.selected,
			filterable: '',
		}
	}

	UNSAFE_componentWillMount() {
		document.addEventListener('click', this.handleGlobalClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleGlobalClick, false);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(nextProps.selected !== null
		&& nextProps.selected !== this.state.selected) {
			this.setState({
				selected: nextProps.selected
			});
		}
	}

	// Global Click Handler
	handleGlobalClick = (event) => {
		const _classList = event.target.classList;
		if(!this.props.multiple
		&& !_classList.contains('ouSelectDropdown')
		&& !_classList.contains('ouSelectDropdown--toggle')
		&& !_classList.contains('ouSelectDropdown--toggle--caret')
		&& !_classList.contains('ouSelectDropdown--toggle--select')
		&& !_classList.contains('ouSelectDropdown--toggle--select--empty')
		&& !_classList.contains('ouSelectDropdown--filter')
		&& !_classList.contains('ouSelectDropdown--filter--reset')
		&& !_classList.contains('ouSelectDropdown--filter--input')) {
			// Check If Delselected (dont close)
			if(_classList.contains('ouSelectDropdown--item')
			&& !_classList.contains('checked')) {
				if(!this.props.closeOnDeselect) return;
			} else if(_classList.contains('ouSelectDropdown--item--text')
			|| _classList.contains('ouSelectDropdown--item--check')) {
				const _parent = event.target.parentElement;
				if(_parent) {
					if(!_parent.classList.contains('checked')) {
						if(!this.props.closeOnDeselect) return;
					}
				}
			} else if(event.target.parentElement
			&& event.target.parentElement.classList.contains('ouSelectDropdown--item--text')) {
			   	const _parentParent = event.target.parentElement.parentElement;
			   	if(_parentParent) {
			   		if(!_parentParent.classList.contains('checked')) {
						if(!this.props.closeOnDeselect) return;
			   		}
			   	}
			}

			// Close Select
			this.setState({
				open: false
			});
		} else if(!_classList.contains(this.state.rootClass)) {
			// Check If Select was Clicked
			if(!event.target.parentElement
			|| !event.target.parentElement.classList.contains(this.state.rootClass)) {
				this.setState({
					open: false
				});
			}
		}
	}

	matchFilter = (value) => {
		if(this.state.filterable.length > 0) {
			let _matches = true;
			this.state.filterable.split(' ').forEach((filterMatch) => {
				if(value.toLowerCase().indexOf(filterMatch.toLowerCase()) === -1) {
					_matches =  false;
				}
			});

			return _matches;
		} else {
			return true;
		}
	}

	render() {
		const _this = this;
		const _state = _this.state;
		const _props = _this.props;
		const _config = _this.props.config;
		const _rootClass = _state.rootClass;

		// Disable & No Config Fallback
		if(_this.props.disable || (Object.keys(_config).length === 0 && _config.constructor === Object)) {
			return (
				<div className={cx(_rootClass, 'ouSelectDropdown', 'checks disabled', 'dropdown')} style={_this.props.style || null}>
					<button className={cx(_rootClass, 'ouSelectDropdown--toggle', 'btn btn-secondary dropdown-toggle')} title={''}>
						<span className={cx(_rootClass, 'ouSelectDropdown--toggle--caret', 'fa fa-caret-down')} aria-hidden="true" />
						<span className={cx(_rootClass, 'ouSelectDropdown--toggle--select--empty')}>
							Keine Auswahl
						</span>
					</button>
				</div>
			)
		}

		// const _tagsOnly = /<\/?[^>]+(>|$)/g;
		const _spanContent = /(<span\b[^<>]*>)[\s\S]*?(<\/span>)/g;
		const _disabled = this.props.disabled;

		// Fetch Config Length and Validate Force
		let _configLength = 0;
		_config.forEach((select) => {
			if(select.display instanceof Array) {
				_configLength += select.display.length;
			} else {
				_configLength += 1;
			}
		});
		let _forceFilterable = false;
		if(_configLength >= _this.props.forceFilterable) _forceFilterable = true;

		// Set Current Config or Build Filter Config
		let _currentConfig = _config;
		if(_this.props.filterable || _forceFilterable) {
			let _filterConfig = [];
			_config.forEach((select) => {
				if(select.display instanceof Array) {
					let _filterGroup = {
						value: select.value,
						display: []
					};

					select.display.forEach((groupSelect) => {
						if(_this.matchFilter(groupSelect.display.replace(_spanContent, '')) === true) {
							_filterGroup.display.push(groupSelect);
						}
					});

					if(_filterGroup.display.length > 0) {
						_filterConfig.push(_filterGroup);
					}
				} else {
					if(_this.matchFilter(select.display.replace(_spanContent, '')) === true) {
						_filterConfig.push(select);
					}
				}
			});

			_currentConfig = _filterConfig;
		}

		// Fetch Current Config Length and Validate Force
		let _currentGroupCount = 0;
		let _currentConfigLength = 0;
		_currentConfig.forEach((select) => {
			if(select.display instanceof Array) {
				_currentConfigLength += select.display.length;
				_currentGroupCount += 1;
			} else {
				_currentConfigLength += 1;
			}
		});

		// Calcualte Height
		const _baseHeight = (16 /* Inner Padding */ + 2 /* Border */ + 2 /* Margin Top */);
		const _itemHeight = (35 + _this.props.itemExtra);
		const _grouHeight = 24;
		const _filtHeight = 40;
		let _selectHeight = (_baseHeight + (_itemHeight * _currentConfigLength) + (_grouHeight * _currentGroupCount));

		// Fetch Height Limit
		if(_selectHeight > _this.props.height) {
			_selectHeight = _this.props.height;
			// Remove Filter Height
			if(_forceFilterable || _this.props.filterable) _selectHeight -= _filtHeight;
		}

		// Build Selection and Validate Selected
		let _selected = _state.selected;
		let _selection = '';
		if(_selected && _selected.length > 0) {
			let _selectionArray = [];

			// Go tough config and look for selected items
			_config.forEach((select) => {
				if(select.display instanceof Array) {
					select.display.forEach((groupSelect) => {
						if(_state.selected.includes(groupSelect.value)) {
							const _selected = groupSelect.value;

							// Check if disabled
							if(!_disabled || !_disabled.includes(_selected)) {
								_selectionArray.push(groupSelect.display.replace(_spanContent, ''));
							} else {
								_selected.splice(_selected.indexOf(_selected), 1);
							}
						}
					});
				} else {
					if(_state.selected.includes(select.value)) {
						const _selected = select.value;

						// CHeck if disabled
						if(!_disabled || !_disabled.includes(_selected)) {
							_selectionArray.push(select.display.replace(_spanContent, ''));
						} else {
							_selected.splice(_selected.indexOf(_selected), 1);
						}
					}
				}
			});

			// Build selection string
			_selection = _selectionArray.join(', ');
		} else {
			_selection = null;
		}

		if(_currentConfig) {
			return (
				<div className={cx(_rootClass, 'ouSelectDropdown', 'checks', 'dropdown', {
					show: _state.open,
					filterable: _props.filterable || _forceFilterable
				})} style={_this.props.style || null}>
					<button className={cx(_rootClass, 'ouSelectDropdown--toggle', 'dropdown-toggle')} title={_selection} onClick={() => {
						_this.setState({
							open: !_state.open
						});
					}}>
						<span className={cx(_rootClass, 'ouSelectDropdown--toggle--caret', 'fa fa-caret-down')} aria-hidden="true" />
						{_selection ? (
							<span className={cx(_rootClass, 'ouSelectDropdown--toggle--select')}>
								{_selection}
							</span>
						) : (
							<span className={cx(_rootClass, 'ouSelectDropdown--toggle--select--empty')}>
								{'<Keine Auswahl>'}
							</span>
						)}
					</button>
					{(_this.props.filterable || _forceFilterable) && (
						<div className={cx(_rootClass, 'ouSelectDropdown--filter')} >
							<span className={cx(_rootClass, 'ouSelectDropdown--filter--reset', 'fa fa-times')} title="Zurücksetzen" onClick={() => {
								_this.setState({
									filterable: ''
								});
							}} />
							<input className={cx(_rootClass, 'ouSelectDropdown--filter--input')} placeholder="Suche..." value={_state.filterable} onChange={(event) => {
								_this.setState({
									filterable: event.target.value
								});
							}} />
						</div>
					)}
					<div className={cx(_rootClass, 'ouSelectDropdown--menu', 'dropdown-menu', {
						show: _state.open
					})} style={{minHeight: (_selectHeight + 'px')}}>
						{_currentConfig.map((select, index) => {
							const _selectVal = select.value;
							const _selectDisplay = select.display;

							if(_selectVal && _selectDisplay) {
								if(_selectDisplay instanceof Array) {
									return (
										<div className={cx(_rootClass, 'ouSelectDropdown--group')} key={'ou_dropdownselect_group_' + index}>
											<span className={cx(_rootClass, 'ouSelectDropdown--group--title')}>
												{_selectVal}
											</span>
											{_selectDisplay.map((groupSelect, groupIndex) => {
												const _groupVal = groupSelect.value;
												const _groupDisplay = groupSelect.display;

												// Build States
												let _disabled = false;
												if(_props.disabled && _props.disabled.includes(_groupVal)) _disabled = true;
												let _checked = false;
												if(_disabled === false && _state.selected && _state.selected.includes(_groupVal)) _checked = true;

												return (
													<button className={cx(_rootClass, 'ouSelectDropdown--item', 'dropdown-item', {
														disabled: _disabled,
														checked: _checked
													})}
													   key={'ou_dropdownselect_group_' + index + '_select_' + groupIndex}
													   onClick={() => {
														if(_this.props.disabled && _this.props.disabled.includes(_groupVal)) return;

														let _newState = {};
														if(_this.state.selected.includes(_groupVal)) {
															_newState = update(_this.state, {
																selected: {
																	$splice: [
																		[_this.state.selected.indexOf(_groupVal), 1]
																	]
																}
															});
														} else {
															if(_this.props.multiple) {
																if(_this.props.maxSelected !== 0 && _this.state.selected.length >= _this.props.maxSelected) return;

																_newState = update(_state, {
																	selected: {
																		$push: [
																			_groupVal
																		]
																	}
																});
															} else {
																_newState = update(_state, {
																	selected: {
																		$set: [
																			_groupVal
																		]
																	}
																});
															}
														}

														_this.setState(_newState);
														_this.props.onSelectChange(_newState.selected);
													}}>
														<span className={cx(_rootClass, 'ouSelectDropdown--item--check', 'fa fa-check')} />
														<span className={cx(_rootClass, 'ouSelectDropdown--item--text')} dangerouslySetInnerHTML={{__html: _groupDisplay}} />
													</button>
												)
											})}
										</div>
									)
								} else {
									// Build States
									let _disabled = false;
									if(_props.disabled && _props.disabled.includes(_selectVal)) _disabled = true;
									let _checked = false;
									if(_disabled === false && _state.selected && _state.selected.includes(_selectVal)) _checked = true;

									return (
										<button
											className={cx(_rootClass, 'ouSelectDropdown--item', 'dropdown-item', {
												disabled: _disabled,
												checked: _checked
											})}
											key={'ou_dropdownselect_select_' + index}
											onClick={() => {
												if(_this.props.disabled
												&& _this.props.disabled.includes(_selectVal)) return;

												let _newState = {};
												if(_state.selected.includes(_selectVal)) {
													_newState = update(_state, {
														selected: {
															$splice: [
																[_state.selected.indexOf(_selectVal), 1]
															]
														}
													});
												} else {
													if(_props.multiple) {
														if(_props.maxSelected !== 0
														&& _state.selected.length >= _props.maxSelected) return;

														_newState = update(_state, {
															selected: {
																$push: [
																	_selectVal
																]
															}
														});
													} else {
														_newState = update(_state, {
															selected: {
																$set: [
																	_selectVal
																]
															}
														});
													}
												}

												_this.setState(_newState);
												_this.props.onSelectChange(_newState.selected);
											}}
										>
											<span className={cx(_rootClass, 'ouSelectDropdown--item--check', 'fa fa-check')} />
											<span className={cx(_rootClass, 'ouSelectDropdown--item--text')} dangerouslySetInnerHTML={{__html: _selectDisplay}} />
										</button>
									)
								}
							} else {
								return null;
							}
						})}
					</div>
					{_props.maxSelected !== 0 && _state.selected.length >= _props.maxSelected && (
						<span className={cx(_rootClass, 'ouSelectDropdown--message')} style={{bottom: ('-' + (_props.height === 0 ? 72 : _props.height + 34) + 'px')
						}}>
							Maximal Auswahl erreicht!
						</span>
					)}
				</div>
			)
		} else return null;
	}
}

OuSelect.propTypes = {
	// Current Selected Items
	selected: PropTypes.array.isRequired,
	// onSelectChange Handler
	onSelectChange: PropTypes.func.isRequired,
	// Select Config
	config: PropTypes.array
}

OuSelect.defaultProps = {
	// Parse the selected value
	selected: [],
	// Parse an onChange event
	onSelectChange: () => {},
	// Set on which item count the filtering will be forced
	forceFilterable: 25,
	// Toggle Select-Menu fltering
	filterable: false,
	// Toggle multiple selcting
	multiple: false,
	// Set the maximum amount of selected items
	maxSelected: 0,
	// Set the max height of the Select-Menu
	height: 0,
	// Add an extra height to each select item (eg. admin-only-ids)
	itemExtra: 0,
	// Disables the Select-Menu
	disable: false,
	// Close the Select-Menu on deselect (single-select-only)
	closeOnDeselect: false
}

export default OuSelect;
