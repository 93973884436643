// React
import /*React,*/ { Component } from 'react';
// import ReactDOM from 'react-dom';
// import PropTypes from 'prop-types';
// Utils
// import { classnames as cx } from 'utils';

class OuInsideBody extends Component {
	render() {
		return null;
	}
}

export default OuInsideBody;
