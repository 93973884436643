import { SelectType } from 'components/styled/select';

export const LOCALE_SELECT_CONFIG = [
	{type: SelectType.Item, value: 'de', label: 'Deutschland'},
	{type: SelectType.Item, value: 'de-at', label: 'Österreich'},
	{type: SelectType.Item, value: 'de-ch', label: 'Schweiz'}
];

export const parseLocale = (pos) => {
	if(pos && LOCALE_SELECT_CONFIG.filter(s => s.value === pos).length > 0) {
		return LOCALE_SELECT_CONFIG.filter(s => s.value === pos)[0]['label'];
	} else return undefined;
}
