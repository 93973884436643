// Utils
import update from 'immutability-helper';
import jwtDecode from 'jwt-decode';

const initialState = {
    authenticated: false,
    token: null,
	hasPermission: hasPermission,
	hasPermissions: hasPermissions,
	hasOnePermission: hasOnePermission
};

function hasPermission(key, value) {
	if(this.token) {
		const encodedToken = jwtDecode(this.token);
		if(encodedToken && typeof encodedToken.scope !== undefined && encodedToken.scope instanceof Object
		&& typeof encodedToken.scope[key] !== undefined && encodedToken.scope[key] instanceof Array
		&& encodedToken.scope[key].includes(value)) {
			return true;
		}
	}

	return false;
}

function hasPermissions(scopes) {
	let hasAll = true;
	if(this.token) {
		const encodedToken = jwtDecode(this.token);
		if(encodedToken && encodedToken.scope) {
			if(scopes.length > 0) {
				scopes.forEach((scope) => {
					if(!encodedToken.scope[scope[0]] || !encodedToken.scope[scope[0]].includes(scope[1])) {
						hasAll = false;
					}
				});
			} else {
				hasAll = false;
			}
		} else {
			hasAll = false;
		}
	} else {
		hasAll = false;
	}

	return hasAll;
}


function hasOnePermission(scopes) {
	let hasOne = false;
	if(this.token) {
		const encodedToken = jwtDecode(this.token);
		if(encodedToken && encodedToken.scope) {
			if(scopes.length > 0) {
				scopes.forEach((scope) => {
					if(encodedToken.scope[scope[0]] && encodedToken.scope[scope[0]].includes(scope[1])) {
						hasOne = true;
					}
				});
			}
		}
	}

	return hasOne;
}

function auth(state = initialState, action) {
    if(action.type === 'AUTH_LOGIN' || action.type === 'AUTH_LOGOUT' || action.type === 'AUTH_CHECK' || action.type === 'AUTH_LOAD') {
		return update(state, {
			authenticated: {
				$set: action.authenticated
			},
			token: {
				$set: action.token
			}
		});
	} else {
        return state;
    }
}

export default auth;
