// React
import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
// Custom Route
import { OuRoute, OuRedirect } from 'components/parts/universal';

// Components (Async)
const OuTestOverview = React.lazy(() => import('./overview/OuTestOverview'));

const OuStores = () => (
	<Suspense>
		<Switch>
			<OuRoute title="Übersicht" path="/test/overview" component={OuTestOverview} />
			{/* No Match */}
			<OuRedirect from='/test' to='/test/overview' />
		</Switch>
	</Suspense>
);

export default OuStores;
