// React
import React, { Component, Suspense } from 'react';
import { withRouter } from 'react-router';
import { Switch, Redirect } from 'react-router-dom';
// Custom Route
import { OuRoute } from 'components/parts/universal';
// Components
import OuStoreSelect from './OuStoreSelect';

// Components (Async)
const OuStoreOverview = React.lazy(() => import(
	/* webpackChunkName: 'ou_store_overview' */
	'./overview/OuStoreOverview'
));

class OuStore extends Component {
	render() {
		const { props: { history, location, match } } = this;
		//
        return (
			<Suspense fallback={<p>Lädt...</p>}>
				<Switch history={history} location={location} match={match} >
					<Redirect exact from='/store/overview' to='/store/overview/10/1' />
					<OuRoute title="Übersicht" path="/store/overview/:max/:current" component={OuStoreOverview} />
					<OuRoute path="/store/:hash" component={OuStoreSelect} />
					{/* No Match */}
					<Redirect from='/store' to='/store/overview/10/1' />
				</Switch>
			</Suspense>
        );
    }
}

export default withRouter(OuStore);
