// React / Router
import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Components (Async)
const OuOverview = React.lazy(() => import('./OuOverview'));
const OuDetail = React.lazy(() => import('./OuDetail'));

const OuOrders = () => (
	<Suspense fallback={<p>Lädt...</p>}>
		<Switch>
			<Redirect exact from='/orders' to='/orders/overview/10/1' />
			<Redirect exact from='/orders/overview' to='/orders/overview/10/1' />
			<Route path="/orders/overview/:maxOrders/:currentPage" component={OuOverview} />
			<Redirect exact from='/orders/detail' to='/orders/overview/10/1' />
			<Route path="/orders/detail/:order" component={OuDetail} />
			{/* No Match */}
			<Redirect from='/' to='/orders/overview/10/1' />
		</Switch>
	</Suspense>
);

export default OuOrders;
