import { PureComponent } from 'react';
import PropTypes from 'prop-types';
//
import uuid from 'uuid/v4';
import { dispatch } from './utils/store';
//
import './OuBreadcrumb.css';

export default class OuBreadcrumb extends PureComponent {
	static propTypes = {
		data: PropTypes.object.isRequired,
		hidden: PropTypes.bool,
		disabled: PropTypes.bool,
		children: PropTypes.element
	}

	static defaultProps = {
		hidden: false,
		disabled: false,
		children: null
	}

	state = {
		id: uuid()
	}

	_dispatch = (action, data) => {
        // Get Crumb ID
		const { id } = this.state;

        // Dispatch Action
		dispatch({
			type: action,
			crumb: { id, ...data }
		});
	}

	UNSAFE_componentWillMount() {
		const { data, hidden, disabled } = this.props;
		if(!hidden) this._dispatch('ADD_CRUMB', { ...data, disabled });
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { data, hidden, disabled} = nextProps;

		if(this.props !== nextProps) {
			this._dispatch('UPDATE_CRUMB', { ...data, disabled });
		}

		if(!this.props.hidden && hidden) {
			this._dispatch('REMOVE_CRUMB', { ...data, disabled });
		}

		if(this.props.hidden && !hidden) {
			this._dispatch('ADD_CRUMB', { ...data, disabled });
		}
	}

	componentWillUnmount() {
		const { data, disabled } = this.props;
		this._dispatch('REMOVE_CRUMB', { ...data, disabled });
	}

	render() {
		return this.props.children;
	}
}
